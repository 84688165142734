<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_wideTransition" />
      <!-- 検索項目 -->
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <div class="search-row-exeBtn first-search-item">
              <!-- 会社名-->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  v-model="companySelected"
                  maxlength="3"
                  :items="companyList"
                  :label="$t('label.lbl_popCompanyNm')"
                  class="txt-single"
                  persistent-hint
                  ><template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span></template
                  >
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 本部-->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  v-model="qualityDivSelected"
                  maxlength="3"
                  :label="$t('label.lbl_headOfficeFuture')"
                  class="txt-single"
                  persistent-hint
                  ><template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span></template
                  >
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 営業所 -->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  v-model="salesOfficeSelected"
                  maxlength="3"
                  :items="salesOfficeList"
                  :label="$t('label.lbl_salesOffice')"
                  class="txt-single"
                  persistent-hint
                  ><template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span></template
                  >
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 取引先 -->
              <div class="search-autocomplete first-search-item" style="float: left">
                <v-autocomplete
                  dense
                  id="get-supplier"
                  v-model="suppliersSelected"
                  maxlength="10"
                  :items="allComSupplierList"
                  :label="$t('label.lbl_supplier')"
                  persistent-hint
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span></template
                  >
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 品目 -->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  v-model="item40Selected"
                  maxlength="3"
                  :items="item40List"
                  :label="$t('label.lbl_item')"
                  class="txt-single"
                  persistent-hint
                  ><template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span></template
                  >
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 基準日ー -->
              <div class="date-style" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="referenceDate"
                  maxlength="10"
                  :label="$t('label.lbl_refernceDate')"
                  @change="changeDate"
                  class="txt-single date-style"
                  :rules="[rules.yyyymmdd]"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
                <!-- :rules="[rules.yyyymmdd, rules.dateCheck]" -->
              </div>
              <span class="require" style="float: left">*</span>
              <div class="calender-btn-area" style="float: left">
                <!-- 基準日カレンダー-->
                <v-menu
                  v-model="referenceDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="referenceDateCal"
                    @input="referenceDateMenu = false"
                    max="2099-12-31"
                  ></v-date-picker>
                  <!-- :allowed-dates="allowedDates" -->
                </v-menu>
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                  <!-- 前日-->
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                  <!-- 次日-->
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <!-- 有償/無償 -->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  v-model="isPaid"
                  maxlength="3"
                  :items="freeCostDivList"
                  :label="$t('label.lbl_isPaid')"
                  class="txt-single"
                  persistent-hint
                  ><template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span></template
                  >
                </v-autocomplete>
              </div>
              <div>
                <!--ボタン領域-->
                <div class="btn-area">
                  <div class="text-label" style="float: left; color: brown">
                    <span v-if="autoUpdateFlag == 'on'" class="nowTime"
                      >自動更新中&nbsp;&nbsp;</span
                    >
                  </div>
                  <!-- 更新間隔 -->
                  <div
                    v-if="autoUpdateFlag == 'off'"
                    class="serch-textbox-arrivalNo"
                    style="float: left"
                  >
                    <v-text-field
                      ref="autoForm"
                      outlined
                      dense
                      v-model="autoUpdateIntervalMin"
                      maxlength="20"
                      :label="$t('label.lbl_autoUpdateInterval')"
                      class="autoUpdate"
                      :rules="[rules.isNumber, rules.autoUpdateMinMaxCheck]"
                      :error-messages="autoUpdateIntervalMinMessage"
                      clear-icon="mdi-close-circle"
                      clearable
                      @blur="onBlur"
                    />
                  </div>
                  <div
                    v-if="autoUpdateFlag == 'on'"
                    class="serch-textbox-arrivalNo"
                    style="float: left"
                  >
                    <v-text-field
                      outlined
                      dense
                      v-model="autoUpdateIntervalMin"
                      maxlength="20"
                      :label="$t('label.lbl_autoUpdateInterval')"
                      class="autoUpdate"
                      disabled
                    />
                  </div>
                  <!-- 自動更新ボタン-->
                  <v-btn id="btn-pack" class="post-btn" @click="autoUpdate">
                    {{ $t("btn.btn_autoUpdate") }}
                  </v-btn>
                  <!-- 検索ボタン -->
                  <v-btn
                    color="primary"
                    id="get-search"
                    class="api-btn"
                    @click="apigetStock('get-search')"
                    >{{ $t("btn.btn_search") }}</v-btn
                  >
                  <!-- 自動更新停止-->
                  <!-- <v-btn id="btn-pack" class="post-btn" @click="autoUpdateStop">自動更新停止</v-btn> -->
                </div>
              </div>
              <!-- 空白 -->
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!-- 検索時間表示部分 -->
              <div class="text-label" style="float: left">
                <span v-if="totalCount.length != 0" class="nowTime">
                  ({{ today }} {{ nowTime }}時点)
                </span>
              </div>
              <!--表示件数切替部分-->
              <!-- <div style="float: right"> -->
              <!--表示件数-->
              <!-- <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div> -->
              <!-- 空白 -->
              <!-- <span class="item-spacer" style="float: left">&nbsp;</span> -->
              <!--表示件数切替ボタン-->
              <!-- <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div> -->
            </div>
          </v-row>
          <!-- 2行目ここまで -->
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          :options.sync="sortOptions"
          height="100%"
        >
          <!-- 検索結果ヘッダ情報 -->
          <template v-slot:[`header.inStockScheduleBeforeThird`]="{ header }">
            <v-row>
              <!-- <v-col style="text-align: center; background-color: lightgray"> -->
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div class="pbox">
                  <div class="inout">入荷</div>
                  <div class="inout">出荷</div>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.inStockScheduleBeforeSecond`]="{ header }">
            <v-row class="text-left">
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div class="pbox">
                  <div class="inout">入荷</div>
                  <div class="inout">出荷</div>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.inStockScheduleBeforeFirst`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div class="pbox">
                  <div class="inout">入荷</div>
                  <div class="inout">出荷</div>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.inStockScheduleCurrent`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div class="pbox">
                  <div class="inout">入荷</div>
                  <div class="inout">出荷</div>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.inStockScheduleAfterFirst`]="{ header }">
            <!-- <v-row style="background-color: lightblue; padding: 0px"> -->
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div class="pbox">
                  <div class="inout">入荷</div>
                  <div class="inout">出荷</div>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.inStockScheduleAfterSecond`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div class="pbox">
                  <div class="inout">入荷</div>
                  <div class="inout">出荷</div>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.inStockScheduleAfterThird`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div class="pbox">
                  <div class="inout">入荷</div>
                  <div class="inout">出荷</div>
                </div>
              </v-col>
            </v-row>
          </template>
          <!-- 検索結果ヘッダ情報 -->
          <!-- 検索結果明細情報 -->
          <!-- 品番/品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <v-row>
              <v-col style="text-align: left">
                <!-- 品番 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}
                    </div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                </v-tooltip>
                <!-- 品番 -->
                <!-- 品名 -->
                <div v-if="item.productName != ''">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" v-bind="attrs" v-on="on">
                        {{ item.productName }}
                      </div>
                    </template>
                    <div>{{ item.productName }}</div>
                  </v-tooltip>
                </div>
                <div v-else>&nbsp;</div>
                <!-- 品名 -->
                <div class="text-overflow">&nbsp;</div>
              </v-col>
            </v-row>
          </template>
          <!-- 品番/品名 -->
          <!-- 項目 -->
          <template v-slot:[`item.items`]="{}">
            <v-row>
              <v-col style="text-align: left">
                <!-- 入荷予定数 -->
                <!-- <div>入荷予定数</div> -->
                <!-- 入荷予定数 -->
                <!-- 出荷予定数 -->
                <!-- <div>出荷予定数</div> -->
                <!-- 出荷予定数 -->
                <!-- 在庫数 -->
                <!-- <div>在庫数</div> -->
                <!-- 在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 項目 -->
          <!-- 基準日-3日 -->
          <template v-slot:[`item.inStockScheduleBeforeThird`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日-3日 入荷予定数 -->
                <div>{{ item.inStockScheduleBeforeThird }}</div>
                <!-- 基準日-3日 入荷予定数 -->
                <!-- 基準日-3日 実績入荷数 -->
                <div>{{ item.inStockActua0BeforeThird }}</div>
                <!-- 基準日-3日 実績入荷数 -->
              </v-col>
              <v-col style="text-align: right">
                <!-- 基準日-3日 出荷予定数 -->
                <div>{{ item.outStockScheduleBeforeThird }}</div>
                <!-- 基準日-3日 出荷予定数 -->
                <!-- 基準日-3日 実績出荷数 -->
                <div>{{ item.outStockActualBeforeThird }}</div>
                <!-- 基準日-3日 実績出荷数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日-3日 -->
          <!-- 基準日-2日 -->
          <template v-slot:[`item.inStockScheduleBeforeSecond`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日-2日 入荷予定数 -->
                <div>{{ item.inStockScheduleBeforeSecond }}</div>
                <!-- 基準日-2日 入荷予定数 -->
                <!-- 基準日-2日 実績入荷数 -->
                <div>{{ item.inStockActualBeforeSecond }}</div>
                <!-- 基準日-2日 実績入荷数 -->
              </v-col>
              <v-col style="text-align: right">
                <!-- 基準日-2日 出荷予定数 -->
                <div>{{ item.outStockScheduleBeforeSecond }}</div>
                <!-- 基準日-2日 出荷予定数 -->
                <!-- 基準日-2日 実績出荷数 -->
                <div>{{ item.outStockActualBeforeSecond }}</div>
                <!-- 基準日-2日 実績出荷数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日-2日 -->
          <!-- 基準日-1日 -->
          <template v-slot:[`item.inStockScheduleBeforeFirst`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日-1日 入荷予定数 -->
                <div>{{ item.inStockScheduleBeforeFirst }}</div>
                <!-- 基準日-1日 入荷予定数 -->
                <!-- 基準日-1日 実績入荷数 -->
                <div>{{ item.inStockActualBeforeFirst }}</div>
                <!-- 基準日-1日 実績入荷数 -->
              </v-col>
              <v-col style="text-align: right">
                <!-- 基準日-1日 出荷予定数 -->
                <div>{{ item.outStockScheduleBeforeFirst }}</div>
                <!-- 基準日-1日 出荷予定数 -->
                <!-- 基準日-1日 実績出荷数 -->
                <div>{{ item.outStockActualBeforeFirst }}</div>
                <!-- 基準日-1日 実績出荷数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日-1日 -->
          <!-- 基準日 -->
          <template v-slot:[`item.inStockScheduleCurrent`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日 入荷予定数 -->
                <div>{{ item.inStockScheduleCurrent }}</div>
                <!-- 基準日 入荷予定数 -->
                <!-- 基準日 実績入荷数 -->
                <div>{{ item.inStockActualCurrent }}</div>
                <!-- 基準日 実績入荷数 -->
              </v-col>
              <v-col style="text-align: right">
                <!-- 基準日 出荷予定数 -->
                <div>{{ item.outStockScheduleCurrent }}</div>
                <!-- 基準日 出荷予定数 -->
                <!-- 基準日 実績出荷数 -->
                <div>{{ item.outStockActualCurrent }}</div>
                <!-- 基準日 実績出荷数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日 -->
          <!-- 基準日+1 -->
          <template v-slot:[`item.inStockScheduleAfterFirst`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+1 入荷予定数 -->
                <div>{{ item.inStockScheduleAfterFirst }}</div>
                <!-- 基準日+1 入荷予定数 -->
                <!-- 基準日+1 実績入荷数 -->
                <div>{{ item.inStockActualAfterFirst }}</div>
                <!-- 基準日+1 実績入荷数 -->
              </v-col>
              <v-col style="text-align: right">
                <!-- 基準日+1 出荷予定数 -->
                <div>{{ item.outStockScheduleAfterFirst }}</div>
                <!-- 基準日+1 出荷予定数 -->
                <!-- 基準日+1 実績出荷数 -->
                <div>{{ item.outStockActualAfterFirst }}</div>
                <!-- 基準日+1 実績出荷数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+1 -->
          <!-- 基準日+2 -->
          <template v-slot:[`item.inStockScheduleAfterSecond`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+2 入荷予定数 -->
                <div>{{ item.inStockScheduleAfterSecond }}</div>
                <!-- 基準日+2 入荷予定数 -->
                <!-- 基準日+2 実績入荷数 -->
                <div>{{ item.inStockActualAfterSecond }}</div>
                <!-- 基準日+2 実績入荷数 -->
              </v-col>
              <v-col style="text-align: right">
                <!-- 基準日+2 出荷予定数 -->
                <div>{{ item.outStockScheduleAfterSecond }}</div>
                <!-- 基準日+2 出荷予定数 -->
                <!-- 基準日+2 実績出荷数 -->
                <div>{{ item.outStockActualAfterSecond }}</div>
                <!-- 基準日+2 実績出荷数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+2 -->
          <!-- 基準日+3 -->
          <template v-slot:[`item.inStockScheduleAfterThird`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+3 入荷予定数 -->
                <div>{{ item.inStockScheduleAfterThird }}</div>
                <!-- 基準日+3 入荷予定数 -->
                <!-- 基準日+3 実績入荷数 -->
                <div>{{ item.inStockActualAfterThird }}</div>
                <!-- 基準日+3 実績入荷数 -->
              </v-col>
              <v-col style="text-align: right">
                <!-- 基準日+3 出荷予定数 -->
                <div>{{ item.outStockScheduleAfterThird }}</div>
                <!-- 基準日+3 出荷予定数 -->
                <!-- 基準日+3 実績出荷数 -->
                <div>{{ item.outStockActualAfterThird }}</div>
                <!-- 基準日+3 実績出荷数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+3 -->
        </v-data-table>

        <!-- <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div> -->
      </v-container>
      <!-- ナビゲーション -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
// import StockInquiryDetails from "../stock/StockInquiryDetails.vue";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";

export default {
  name: appConfig.SCREEN_ID.P_SRG_004,
  components: {
    Loading,
    sideMenu,
    // StockInquiryDetails,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    rules: {
      item40Required: (value) => !!value || i18n.tc("check.chk_select_item40"),
      displayRequired: (value) => !!value || i18n.tc("check.chk_select_displayPeriod"),
      // 更新間隔に何も入力せずフォーカスアウトしてもメッセージ表示しない
      // autoUpdateReset: (value) => !!value || this.autoForm.reset(),
      autoUpdateMinMaxCheck: (value) =>
        (value > 0 && value < 100) || i18n.tc("check.chk_limitLength_1-99"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      autoUpdateInputCheck: function (value) {
        let num = Number(value);
        if (num < 1 || num > 99) {
          return i18n.tc("check.chk_limitLength_1-99");
        }
        // return true;
      },

      /**
       * 日付の当日以降チェック
       * @param {*} value チェック対象日付（yyyy-MM-dd）
       */
      dateCheck(value) {
        if (dateTimeHelper.isDateAfterTheDay(value)) {
          // 当日以降の場合は処理なし
          return true;
        } else {
          // 当日より前日付の場合はエラー
          return i18n.tc("check.chk_DateAfterTheDay");
        }
      },
    },
    // 検索時点の日時
    year: "",
    today: "",
    nowTime: "",
    search: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    totalCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 選択された取引先
    companySelected: "",
    // 選択された会社
    suppliersSelected: "",
    // 選択された品目
    item40Selected: "",
    item40SelectedMessage: "",
    // 選択された推移
    qualityDivSelected: "",
    // 選択された品番/品名
    salesOfficeSelected: "",
    // 選択された営業所
    productCodeSelected: "",
    //選択された表示期間
    displayPeriodSelected: "",
    // メニュー
    openMenu: null,
    // 詳細ポップアップ
    dialogDetails: false,
    // ソート
    sortItem: "",
    sortOptions: {},
    // 更新間隔
    autoUpdateInterval: "",
    autoUpdateIntervalMin: null,
    autoUpdateIntervalMinMessage: "",
    interval4times: "",
    autoUpdateFlag: "off",
    // 有償無償
    isPaid: "",
    freeCostDivList: [],

    // ヘッダ
    headerItems: [
      // 会社
      {
        text: i18n.tc("label.lbl_popCompanyNm"),
        value: "productCnCd",
        width: "220px",
        class: "product-fixed",
        align: "left",
        sortable: false,
      },
      // 本部
      // {
      //   text: i18n.tc("label.lbl_headOffice"),
      //   value: "",
      //   width: "90px",
      //   class: "items-fixed",
      //   align: "left",
      //   sortable: false,
      // },
      // 事業部
      // {
      //   text: i18n.tc("label.lbl_businessPart"),
      //   value: "",
      //   width: "120px",
      //   class: "items-fixed2",
      //   align: "left",
      //   sortable: false,
      // },
      // 営業所
      {
        text: i18n.tc("label.lbl_salesOffice"),
        value: "",
        width: "100px",
        class: "items-fixed3",
        align: "left",
        sortable: false,
      },
      // 取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "",
        width: "120px",
        class: "items-fixed4",
        align: "left",
        sortable: false,
      },
      // 品目
      {
        text: i18n.tc("label.lbl_item"),
        value: "",
        width: "120px",
        class: "items-fixed5",
        align: "left",
        sortable: false,
      },
    ],
    inputList: [],
    // 検索結果
    companyList: [],
    // 会社
    salesOfficeList: [],
    // 営業所
    item40List: [],
    // 品目
    inputStockList: [],
    // 検索結果
    transitionList: [],
    // 基準日カレンダー
    referenceDateCal: "",
    // 基準日
    referenceDate: "",
    referenceDateMenu: false,
    // 取引先プルダウン
    allComSupplierList: [],
    // 推移プルダウン
    qualityDivList: [],
    //表示期間プルダウン
    displayPeriodList: [],
    //表記機関初期表示情報
    displayPeriodLnitial: [],
    suppliersSelectedName: "",
    lot: "",
    qualityDiv: "",
    itemName: "",
    itemStandardSid: "",
    // 品番品名プルダウン
    productCodeList: [],
    // dummy_input: {
    //   productNm: "製品名",
    //   productCd: "a",
    //   productAcc: "製品付帯",
    //   productQty: "0",
    //   caseSu: "0",
    //   ballSu: "0",
    //   baraSu: "0",
    //   lot: "lot",
    //   expireDate: "2022/01/13",
    // },
  }),
  methods: {
    // 更新間隔からフォーカスアウトするとv-text-field初期化
    onBlur() {
      if (this.autoUpdateIntervalMin == "" || this.autoUpdateIntervalMin == null) {
        this.$refs.autoForm.reset();
      }
    },
    // テーブルの余白調整
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.apigetStock("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.apigetStock("get-search");
    },
    // 初期化
    init() {
      this.getInitData();
      // this.apigetCompany();
      this.setCompany();
      this.apigetSalesOffice();
      this.apigetAllComSupplier();
      this.apiget40Item();
      this.referenceDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());

      // this.tableDate(new Date());
    },
    // 初期データ取得
    getInitData() {
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      //推移をコードマスタから取得
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.TRANSITION_TYPE);
      //表示期間をコードマスタから取得
      const displayPeriod = getParameter.getCodeMst(appConfig.CODETYPE.DISPLAY_PERIOD);
      const displayPeriodLl = getParameter.getCodeMst(appConfig.CODETYPE.DISPLAY_PERIOD_LNITIAL);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);

      Promise.all([clientList, qualityList, displayPeriod, displayPeriodLl, freeCostList])
        .then((result) => {
          console.log("getInitData() result", result);
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.qualityDivList = result[1];
          this.displayPeriodList = result[2];
          this.displayPeriodLnitial = result[3];
          this.freeCostDivList = result[4];
          //表示期間初期値設定
          // for (var i = 0; i < this.displayPeriodLnitial.length; i++) {
          //   if (this.displayPeriodLnitial[i].value == "01") {
          //     this.displayPeriodSelected = this.displayPeriodLnitial[i].text;
          //   }
          // }
          this.displayPeriodSelected = this.displayPeriodList[0].value;
          this.tableDate(new Date());
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 取引先コードセット処理
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    getClientName() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].text;
        }
      }
    },
    // 品番セット処理
    setProductCode() {
      for (var i = 0; i < this.productCodeList.length; i++) {
        if (this.productCodeList[i].value == this.productCodeSelected) {
          return this.productCodeList[i].name;
        }
      }
    },
    // 推移セット処理
    setStsArrival() {
      for (var i = 0; i < this.qualityDivList.length; i++) {
        if (this.qualityDivList[i].value == this.qualityDivSelected) {
          return this.qualityDivList[i].text;
        }
      }
    },

    convertStringToNumber(val) {
      if (typeof val !== "string" || val === "") return false;
      const num = Number(val);
      // 数値に変換できない場合はNaNが返る
      return isNaN(num) ? false : num;
    },

    // 在庫推移検索
    apigetStock(val) {
      if (val != null) {
        // this.autoUpdateIntervalMin = null;
        // 現在時刻を表示
        var now = new Date();
        this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
        this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);

        if (this.referenceDate == "" || this.referenceDate == null) {
          return;
        }
        // 検索時 更新間隔のメッセージ初期化
        if (this.autoUpdateFlag == "off") {
          //rulesからのメッセージ初期化
          this.$refs.autoForm.reset();
          //error-messagesからのメッセージ初期化
          this.autoUpdateIntervalMinMessage = "";
        }
        // this.item40SelectedMessage = "";
        // this.autoUpdateIntervalMinMessage = "";
        // 品目が選択されてない場合、処理しない
        // if (this.item40Selected == null || this.item40Selected == "") {
        //   this.item40SelectedMessage = i18n.tc("check.chk_select_item40");
        //   return;
        // }
        const config = this.$httpClient.createGetApiRequestConfig();

        // ローディング画面表示ON
        this.loadingCounter = 1;

        config.params.reqComOfficeSid = "2100000116";
        config.params.reqComDeviceImei = "WEB";
        config.params.reqComAppVersion = appConfig.APPVERSION;
        config.params.reqComExecUser = 116;
        config.params.reqComExecTimestamp = dateTimeHelper.convertUTC();
        config.params.reqComRequestId = "3738ef2d-1ff9-4002-a170-853eb105820d";
        // config.params.reqComPaginationFlg = 0;
        // config.params.reqComPageIndex = 1;
        config.params.reqComPageLimit = 100;
        config.params.reqComLanguageCd = "ja";
        config.params.reqComReferenceDate = dateTimeHelper.convertUTC();
        // config.params.reqComSortItem = ;
        config.params.reqComTimeZone = dateTimeHelper.getTimeZone();

        // 品目
        if (this.item40Selected) {
          config.params.items40Sid = this.item40Selected;
        }
        // 営業所
        if (this.salesOfficeSelected) {
          config.params.officeSid = this.salesOfficeSelected;
        }
        // 取引先
        if (this.suppliersSelected) {
          config.params.clientSid = this.suppliersSelected;
        }
        // 基準日
        if (this.referenceDateCal) {
          let dateRange = this.referenceDateCal + " 00:00:00.000";
          config.params.baseDate = dateTimeHelper.convertUTC(dateRange);
        }
        // 有償無償
        if (this.isPaid) {
          config.params.freeCostDiv = this.isPaid;
        }

        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }

        // if (this.page >= 2 && this.inputList.length == 0) {
        //   config.params.reqComPageIndex = "1";
        //   this.page = 1;
        // } else {
        //   config.params.reqComPageIndex = this.page;
        // }

        // ページング
        config.params.reqComPaginationFlg = "0";
        // config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // console.debug("apigetStock() Config", config);
        //APIに渡す値をconfig.paramsにセット==================================//

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_OFFICEALL_TRANSITION, config)
            .then((response) => {
              console.log("apigetStock() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              //headerItems内削除
              this.headerItems.splice(0, this.headerItems.length);

              //headerItems設定-----------------------------------------//
              this.headerItems.push(
                // 会社名
                {
                  text: i18n.tc("label.lbl_popCompanyNm"),
                  value: "compName",
                  width: "130px",
                  class: "product-fixed",
                  align: "left",
                  sortable: false,
                }
              );

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                if (jsonData.resCom.resComPagination != null) {
                  this.headerItems.push(
                    // 本部
                    // {
                    //   text: i18n.tc("label.lbl_headOffice"),
                    //   value: "items",
                    //   width: "80px",
                    //   class: "items-fixed",
                    //   align: "left",
                    //   sortable: false,
                    // },
                    // 事業部
                    // {
                    //   text: i18n.tc("label.lbl_businessPart"),
                    //   value: "",
                    //   width: "90px",
                    //   class: "items-fixed2",
                    //   align: "left",
                    //   sortable: false,
                    // },
                    // 営業所
                    {
                      text: i18n.tc("label.lbl_salesOffice"),
                      value: "officeName",
                      width: "100px",
                      class: "items-fixed3",
                      align: "left",
                      sortable: false,
                    },
                    // 取引先
                    {
                      text: i18n.tc("label.lbl_supplier"),
                      value: "clientName",
                      width: "150px",
                      class: "items-fixed4",
                      align: "left",
                      sortable: false,
                    },
                    // 品目
                    {
                      text: i18n.tc("label.lbl_item"),
                      value: "items40Name",
                      width: "100px",
                      class: "items-fixed5",
                      align: "left",
                      sortable: false,
                    }
                  );
                } else {
                  this.headerItems.push(
                    // 本部
                    // {
                    //   text: i18n.tc("label.lbl_headOffice"),
                    //   value: "items",
                    //   width: "80px",
                    //   class: "items-fixed-search",
                    //   align: "left",
                    //   sortable: false,
                    // },
                    // 事業部
                    // {
                    //   text: i18n.tc("label.lbl_businessPart"),
                    //   value: "",
                    //   width: "90px",
                    //   class: "items-fixed-search2",
                    //   align: "left",
                    //   sortable: false,
                    // },
                    // 営業所
                    {
                      text: i18n.tc("label.lbl_salesOffice"),
                      value: "officeName",
                      width: "80px",
                      class: "items-fixed-search3",
                      align: "left",
                      sortable: false,
                    },
                    // 取引先
                    {
                      text: i18n.tc("label.lbl_supplier"),
                      value: "clientName",
                      width: "140px",
                      class: "items-fixed-search4",
                      align: "left",
                      sortable: false,
                    },
                    // 品目
                    {
                      text: i18n.tc("label.lbl_item"),
                      value: "items40Name",
                      width: "80px",
                      class: "items-fixed-search5",
                      align: "left",
                      sortable: false,
                    }
                  );
                }
              } else {
                this.headerItems.push(
                  // 本部
                  // {
                  //   text: i18n.tc("label.lbl_headOffice"),
                  //   value: "items",
                  //   width: "80px",
                  //   class: "items-fixed",
                  //   align: "left",
                  //   sortable: false,
                  // },
                  // 事業部
                  // {
                  //   text: i18n.tc("label.lbl_businessPart"),
                  //   value: "",
                  //   width: "90px",
                  //   class: "items-fixed2",
                  //   align: "left",
                  //   sortable: false,
                  // },
                  // 営業所
                  {
                    text: i18n.tc("label.lbl_salesOffice"),
                    value: "officeName",
                    width: "80px",
                    class: "items-fixed3",
                    align: "left",
                    sortable: false,
                  },
                  // 取引先
                  {
                    text: i18n.tc("label.lbl_supplier"),
                    value: "clientName",
                    width: "140px",
                    class: "items-fixed4",
                    align: "left",
                    sortable: false,
                  },
                  // 品目
                  {
                    text: i18n.tc("label.lbl_item"),
                    value: "items40Name",
                    width: "80px",
                    class: "items-fixed5",
                    align: "left",
                    sortable: false,
                  }
                );
              }

              this.tableDate(new Date(this.referenceDateCal + " 23:59:59"));
              //headerItems設定-----------------------------------------//

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                // this.pageCount = jsonData.resCom.resComPagination.totalPage;
                // this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const list = [];
                //const stockAllList = [];
                this.transition = jsonData.resIdv.officeSumList;
                this.totalCount = this.transition.length;
                for (let i = 0; i < this.transition.length; i++) {
                  list.push({
                    compName: this.transition[i].compName, // 会社名
                    officeName: this.transition[i].officeName, // 営業所
                    clientName: this.transition[i].clientName, // 取引先
                    items40Name: this.transition[i].items40Name, // 品目
                    productName: this.transition[i].itemName, // 品名
                    inStockScheduleBeforeThird: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockScheduleBeforeThird)
                    ), // 基準日-3日 入荷予定数
                    inStockActua0BeforeThird: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockActua0BeforeThird)
                    ), // 基準日-3日 実績入荷数
                    outStockScheduleBeforeThird: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockScheduleBeforeThird)
                    ), // 基準日-3日 出荷予定数
                    outStockActualBeforeThird: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockActualBeforeThird)
                    ), // 基準日-3日 実績出荷数
                    inStockScheduleBeforeSecond: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockScheduleBeforeSecond)
                    ), // 基準日-2日 入荷予定数
                    inStockActualBeforeSecond: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockActualBeforeSecond)
                    ), // 基準日-2日 実績入荷数
                    outStockScheduleBeforeSecond: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockScheduleBeforeSecond)
                    ), // 基準日-2日 出荷予定数
                    outStockActualBeforeSecond: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockActualBeforeSecond)
                    ), // 基準日-2日 実績出荷数
                    inStockScheduleBeforeFirst: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockScheduleBeforeFirst)
                    ), // 基準日-1日 入荷予定数
                    inStockActualBeforeFirst: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockActualBeforeFirst)
                    ), // 基準日-1日 実績入荷数
                    outStockScheduleBeforeFirst: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockScheduleBeforeFirst)
                    ), // 基準日-1日 出荷予定数
                    outStockActualBeforeFirst: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockActualBeforeFirst)
                    ), // 基準日-1日 実績出荷数
                    inStockScheduleCurrent: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockScheduleCurrent)
                    ), // 基準日 入荷予定数
                    inStockActualCurrent: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockActualCurrent)
                    ), // 基準日 実績入荷数
                    outStockScheduleCurrent: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockScheduleCurrent)
                    ), // 基準日 出荷予定数
                    outStockActualCurrent: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockActualCurrent)
                    ), // 基準日 実績出荷数
                    inStockScheduleAfterFirst: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockScheduleAfterFirst)
                    ), // 基準日+1日 入荷予定数
                    inStockActualAfterFirst: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockActualAfterFirst)
                    ), // 基準日+1日 実績入荷数
                    outStockScheduleAfterFirst: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockScheduleAfterFirst)
                    ), // 基準日+1日 出荷予定数
                    outStockActualAfterFirst: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockActualAfterFirst)
                    ), // 基準日+1日 実績出荷数
                    inStockScheduleAfterSecond: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockScheduleAfterSecond)
                    ), // 基準日+2日 入荷予定数
                    inStockActualAfterSecond: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockActualAfterSecond)
                    ), // 基準日+2日 実績入荷数
                    outStockScheduleAfterSecond: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockScheduleAfterSecond)
                    ), // 基準日+2日 出荷予定数
                    outStockActualAfterSecond: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockActualAfterSecond)
                    ), // 基準日+2日 実績出荷数
                    inStockScheduleAfterThird: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockScheduleAfterThird)
                    ), // 基準日+3日 入荷予定数
                    inStockActualAfterThird: commonUtil.formatToCurrency(
                      Number(this.transition[i].inStockActualAfterThird)
                    ), // 基準日+3日 実績入荷数
                    outStockScheduleAfterThird: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockScheduleAfterThird)
                    ), // 基準日+3日 出荷予定数
                    outStockActualAfterThird: commonUtil.formatToCurrency(
                      Number(this.transition[i].outStockActualAfterThird)
                    ), // 基準日+3日 実績出荷数
                  });
                }
                this.inputList = list;
                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject(resolve);
              }
            })
            .catch((resolve) => {
              console.error("apigetStock() Resolve", resolve);
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    // 会社固定設定
    setCompany() {
      const list = [];
      list.push({
        text: "日本梱包輸送倉庫株式会社", // 会社名
        value: "1000000100",
        name: "日本梱包輸送倉庫株式会社",
      });
      this.companyList = list;
    },

    // 会社取得
    apigetCompany() {
      const config = this.$httpClient.createGetApiRequestConfig();

      // ローディング画面表示ON
      this.loadingCounter = 1;

      config.params.reqComCompanySid = sessionStorage.getItem("comp_sid");
      config.params.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      config.params.reqComDeviceImei = "WEB";
      config.params.reqComAppVersion = appConfig.APPVERSION;
      config.params.reqComExecUser = sessionStorage.getItem("usercode");
      config.params.reqComExecTimestamp = dateTimeHelper.convertUTC();
      config.params.reqComRequestId = "3738ef2d-1ff9-4002-a170-853eb105820d";
      config.params.reqComPageIndex = 1;
      config.params.reqComPageLimit = 100;
      config.params.reqComLanguageCd = sessionStorage.getItem("lang");
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC();
      // config.params.reqComSortItem = "";
      config.params.reqComTimeZone = dateTimeHelper.getTimeZone();

      // if (this.page >= 2 && this.inputList.length == 0) {
      //   config.params.reqComPageIndex = "1";
      //   this.page = 1;
      // } else {
      //   config.params.reqComPageIndex = this.page;
      // }

      // ページング
      config.params.reqComPaginationFlg = "0";
      // config.params.reqComPaginationFlg = "1";
      // config.params.reqComPageLimit = this.itemsPerPage;
      console.log("apigetCompany() Config", config);
      //APIに渡す値をconfig.paramsにセット==================================//

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_CLIENTLIST_TRANSITION, config)
          .then((response) => {
            console.log("apigetCompany() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // this.tableDate(new Date(this.referenceDateCal + " 23:59:59"));
            //headerItems設定-----------------------------------------//

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // this.pageCount = jsonData.resCom.resComPagination.totalPage;
              // this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              //const stockAllList = [];

              this.transition = jsonData.resIdv.clientList;

              for (let i = 0; i < this.transition.length; i++) {
                list.push({
                  text: `${this.transition[0].compName}`, // 営業所
                  value: this.transition[0].compSid,
                  name: this.transition[0].compName,
                });
              }
              this.companyList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetCompany() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 営業所取得
    apigetSalesOffice() {
      const config = this.$httpClient.createGetApiRequestConfig();

      // ローディング画面表示ON
      this.loadingCounter = 1;

      config.params.reqComCompanySid = sessionStorage.getItem("comp_sid");
      config.params.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      config.params.reqComDeviceImei = "WEB";
      config.params.reqComAppVersion = appConfig.APPVERSION;
      config.params.reqComExecUser = sessionStorage.getItem("usercode");
      config.params.reqComExecTimestamp = dateTimeHelper.convertUTC();
      config.params.reqComRequestId = "3738ef2d-1ff9-4002-a170-853eb105820d";
      // config.params.reqComPaginationFlg = 0;
      config.params.reqComPageIndex = 1;
      config.params.reqComPageLimit = 100;
      config.params.reqComLanguageCd = sessionStorage.getItem("lang");
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC();
      // config.params.reqComSortItem = "";
      config.params.reqComTimeZone = dateTimeHelper.getTimeZone();

      // if (this.page >= 2 && this.inputList.length == 0) {
      //   config.params.reqComPageIndex = "1";
      //   this.page = 1;
      // } else {
      //   config.params.reqComPageIndex = this.page;
      // }

      // ページング
      config.params.reqComPaginationFlg = "0";
      // config.params.reqComPaginationFlg = "1";
      // config.params.reqComPageLimit = this.itemsPerPage;
      console.log("apigetSalesOffice() Config", config);
      //APIに渡す値をconfig.paramsにセット==================================//

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_OFFICELIST_TRANSITION, config)
          .then((response) => {
            console.log("apigetSalesOffice() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // this.tableDate(new Date(this.referenceDateCal + " 23:59:59"));
            //headerItems設定-----------------------------------------//

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // this.pageCount = jsonData.resCom.resComPagination.totalPage;
              // this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              //const stockAllList = [];

              this.transition = jsonData.resIdv.officeList;

              for (let i = 0; i < this.transition.length; i++) {
                if (this.transition[i].relatedPartySid != null) {
                  list.push({
                    text: `${this.transition[i].relatedPartyNameShort}`, // 営業所
                    value: this.transition[i].relatedPartySid,
                    name: this.transition[i].relatedPartyNameShort,
                  });
                }
              }
              this.salesOfficeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetSalesOffice() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 取引先取得
    apigetAllComSupplier() {
      const config = this.$httpClient.createGetApiRequestConfig();

      // ローディング画面表示ON
      this.loadingCounter = 1;

      config.params.reqComCompanySid = sessionStorage.getItem("comp_sid");
      config.params.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      config.params.reqComDeviceImei = "WEB";
      config.params.reqComAppVersion = appConfig.APPVERSION;
      config.params.reqComExecUser = sessionStorage.getItem("usercode");
      config.params.reqComExecTimestamp = dateTimeHelper.convertUTC();
      config.params.reqComRequestId = "3738ef2d-1ff9-4002-a170-853eb105820d";
      // config.params.reqComPaginationFlg = 0;
      config.params.reqComPageIndex = 1;
      config.params.reqComPageLimit = 100;
      config.params.reqComLanguageCd = sessionStorage.getItem("lang");
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC();
      // config.params.reqComSortItem = "";
      config.params.reqComTimeZone = dateTimeHelper.getTimeZone();

      // if (this.page >= 2 && this.inputList.length == 0) {
      //   config.params.reqComPageIndex = "1";
      //   this.page = 1;
      // } else {
      //   config.params.reqComPageIndex = this.page;
      // }

      // ページング
      config.params.reqComPaginationFlg = "0";
      // config.params.reqComPaginationFlg = "1";
      // config.params.reqComPageLimit = this.itemsPerPage;
      console.log("apigetAllComSupplier() Config", config);
      //APIに渡す値をconfig.paramsにセット==================================//

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_CLIENTLIST_TRANSITION, config)
          .then((response) => {
            console.log("apigetAllComSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // this.tableDate(new Date(this.referenceDateCal + " 23:59:59"));
            //headerItems設定-----------------------------------------//

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // this.pageCount = jsonData.resCom.resComPagination.totalPage;
              // this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              //const stockAllList = [];

              this.transition = jsonData.resIdv.clientList;

              for (let i = 0; i < this.transition.length; i++) {
                list.push({
                  text: `${this.transition[i].compName}`, // 営業所
                  value: this.transition[i].compSid,
                  name: this.transition[i].compName,
                });
              }
              this.allComSupplierList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetAllComSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 品目取得
    apiget40Item() {
      const config = this.$httpClient.createGetApiRequestConfig();

      // ローディング画面表示ON
      this.loadingCounter = 1;

      config.params.reqComCompanySid = sessionStorage.getItem("comp_sid");
      config.params.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      config.params.reqComDeviceImei = "WEB";
      config.params.reqComAppVersion = appConfig.APPVERSION;
      config.params.reqComExecUser = sessionStorage.getItem("usercode");
      config.params.reqComExecTimestamp = dateTimeHelper.convertUTC();
      config.params.reqComRequestId = "3738ef2d-1ff9-4002-a170-853eb105820d";
      // config.params.reqComPaginationFlg = 0;
      config.params.reqComPageIndex = 1;
      config.params.reqComPageLimit = 100;
      config.params.reqComLanguageCd = sessionStorage.getItem("lang");
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC();
      // config.params.reqComSortItem = "";
      config.params.reqComTimeZone = dateTimeHelper.getTimeZone();

      // if (this.page >= 2 && this.inputList.length == 0) {
      //   config.params.reqComPageIndex = "1";
      //   this.page = 1;
      // } else {
      //   config.params.reqComPageIndex = this.page;
      // }

      // ページング
      config.params.reqComPaginationFlg = "0";
      // config.params.reqComPaginationFlg = "1";
      // config.params.reqComPageLimit = this.itemsPerPage;
      console.log("apiget40Item() Config", config);
      //APIに渡す値をconfig.paramsにセット==================================//

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_40ITEMLIST_TRANSITION, config)
          .then((response) => {
            console.log("apiget40Item() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // this.tableDate(new Date(this.referenceDateCal + " 23:59:59"));
            //headerItems設定-----------------------------------------//

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // this.pageCount = jsonData.resCom.resComPagination.totalPage;
              // this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              //const stockAllList = [];

              this.transition = jsonData.resIdv.Item40List;

              for (let i = 0; i < this.transition.length; i++) {
                list.push({
                  text: `${this.transition[i].items40Name}`, // 品目
                  value: this.transition[i].items40Sid,
                  name: this.transition[i].items40Name,
                });
              }
              this.item40List = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apiget40Item() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 検索結果の日付(可変)をセット
     */
    tableDate(dt) {
      this.headerItems.push(
        // 基準日-3
        {
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() - 3)).substr(5, 5)),
          value: "inStockScheduleBeforeThird",
          // value: "recieveQty",
          width: "150px",
          align: "left",
          sortable: false,
        },
        // 基準日-2
        {
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
          value: "inStockScheduleBeforeSecond",
          // value: "recieveQty",
          width: "150px",
          align: "left",
          sortable: false,
        },
        // 基準日-1
        {
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
          value: "inStockScheduleBeforeFirst",
          // value: "recieveQty",
          width: "150px",
          align: "left",
          sortable: false,
        },
        // 基準日
        {
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
          value: "inStockScheduleCurrent",
          // value: "recieveQty",
          width: "150px",
          align: "left",
          sortable: false,
        },
        // 基準日+1
        {
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
          value: "inStockScheduleAfterFirst",
          // value: "recieveQty",
          width: "150px",
          align: "left",
          sortable: false,
        },
        // 基準日+2
        {
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
          value: "inStockScheduleAfterSecond",
          // value: "recieveQty",
          width: "150px",
          align: "left",
          sortable: false,
        },
        // 基準日+3
        {
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
          value: "inStockScheduleAfterThird",
          // value: "recieveQty",
          width: "150px",
          align: "left",
          sortable: false,
        }
      );
      //基準日～基準日+3日分
      // for (let i = 0; i < Number(this.displayPeriodSelected - 4); i++) {
      //   this.headerItems.push({
      //     text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
      //     value: "inScheduleQuantity" + ("00" + i).slice(-2),
      //     // value: "waitEnterQty",
      //     width: "150px",
      //     align: "left",
      //     sortable: false,
      //   });
      // }
    },
    /**
     * 検索ボタンIDから実行（自動更新用）
     */
    autoUpdateId() {
      //clearInterval(interval4times);
      document.getElementById("get-search").click();
      console.log("autoUpdateId");
    },

    /**
     * 自動更新
     */
    autoUpdate() {
      // メッセージを空にする
      if (this.autoUpdateIntervalMin == null || this.autoUpdateIntervalMin == "") {
        // デフォルト時間を10分に設定
        this.autoUpdateIntervalMin = "10";
      }
      this.autoUpdateIntervalMinMessage = "";
      // 更新間隔が空の場合
      // if (this.autoUpdateIntervalMin == null || this.autoUpdateIntervalMin == "") {
      //   this.autoUpdateIntervalMinMessage = "";
      //   this.autoUpdateIntervalMinMessage = i18n.tc("check.chk_autoUpdate");
      //   return;
      // }
      // 更新間隔が1~99以外は自動更新が実行されないよう
      if (this.autoUpdateIntervalMin < 1 || this.autoUpdateIntervalMin > 99) {
        return;
      }
      // const convertStringToNumber = (n) => {
      //   if (typeof nthis.item40SelectedMessage = ""; !== "string" || n === "") return false;
      //   const num = Number(n);
      //   // 数値に変換できない場合はNaNが返る
      //   return isNaN(num) ? false : num;
      // };

      // 更新間隔が数字以外の場合は自動更新が実行されないよう
      if (!Number.isInteger(this.convertStringToNumber(this.autoUpdateIntervalMin))) {
        // this.item40SelectedMessage = "";
        return;
      }

      // 自動更新フラグ
      if (this.autoUpdateFlag == "off") {
        this.autoUpdateFlag = "on";
        // 入力された分をミリ秒単位に変更
        let autoUpdateInterval = this.autoUpdateIntervalMin * 60000;
        // let interval4times = setInterval(this.apigetStock, this.autoUpdateInterval);
        this.interval4times = setInterval(this.autoUpdateId, autoUpdateInterval);
        // document.getElementById("get-search").click();
        console.log("interval4times: " + this.interval4times);
      } else {
        // 自動更新停止
        this.autoUpdateFlag = "off";
        clearInterval(this.interval4times);
        console.log("clearInterval");
      }
    },
    /**
     * 自動更新停止
     */
    // autoUpdateStop() {
    //   // clearInterval(interval4times);
    //   console.log();
    // },

    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // // 既存品番/品名を選択時
      let val = this.productCodeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.productCodeList = this.productCodeList.filter((v) => v.value == value);
    },
    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    // 今日以前の日付を選択できないようにする
    // allowedDate(val) {
    //   let today = new Date();
    //   today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    //   return today <= new Date(val);
    // },
    /**
     * 基準日を－１日します。
     */
    prevDate() {
      // 今日以前の日付を選択できないように制御
      // let today = dateTimeHelper.convertJST();
      // if (this.referenceDateCal == dateTimeHelper.dateCalc(today)) {
      //   return;
      // }
      let date = new Date(this.referenceDateCal);
      this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.referenceDateCal);
      this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.referenceDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.referenceDateCal = null;
      }
    },
    /**
     * カレンダーの当日以降のみ選択できるようにするメソッド
     * @param {*} val カレンダーの日付
     */
    // allowedDates(val) {
    //   return dateTimeHelper.isDateAfterTheDay(val);
    // },
  },
  computed: {},
  watch: {
    //取引先変更時
    suppliersSelected() {
      //品番/品名リストクリア
      this.nameSelected = "";
      this.nameList = [];
    },
    // suppliersSelected() {
    // autoUpdateIntervalMin() {
    // 品番/品名リストクリア
    // this.autoUpdateIntervalMin = "";
    // this.item40List = [];
    // },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    referenceDateCal(val) {
      this.referenceDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.apigetStock("");
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SRG_004_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.apigetStock("");
        }
      },
      deep: true,
    },

    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.productCodeList = list.concat(result[0]);
              this.productCodeList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.productCodeList.length = 0;
        }
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.autoUpdate ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 110px;
}

.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 110px;
}

.scrollType::-webkit-scrollbar {
  display: none;
}

.scrollType::-webkit-scrollbar {
  display: none;
}

.scrollTypeClose {
  overflow-y: scroll;
}

.table-scroll-host {
  grid-area: content;
  overflow: scroll;
}

.text-overflow {
  // v-tooltip
  width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//会社(固定 ヘッダ)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}

//会社(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr:nth-child(odd) td {
  background-color: #dde5f0;
}

// //本部(固定 ヘッダ 初期表示)
// #listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed {
//   position: sticky !important;
//   left: 220px !important;
//   z-index: 6 !important;
// }

// //本部(固定 ヘッダ 検索時)
// #listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed-search {
//   position: sticky !important;
//   left: 150px !important;
//   z-index: 6 !important;
// }

// //本部(固定 明細)
// #listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
//   position: sticky !important;
//   left: 150px !important;
//   z-index: 5 !important;
// }

// //事業部(固定 ヘッダ 初期表示)
// #listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed2 {
//   position: sticky !important;
//   left: 310px !important;
//   z-index: 6 !important;
// }

// //事業部(固定 ヘッダ 検索時)
// #listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed-search2 {
//   position: sticky !important;
//   left: 230px !important;
//   z-index: 6 !important;
// }

// //事業部(固定 明細)
// #listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(3) {
//   position: sticky !important;
//   left: 230px !important;
//   z-index: 5 !important;
// }

//営業所(固定 ヘッダ 初期表示)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed3 {
  position: sticky !important;
  left: 220px !important;
  z-index: 6 !important;
}

//営業所(固定 ヘッダ 検索時)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed-search3 {
  position: sticky !important;
  left: 130px !important;
  z-index: 6 !important;
}

//営業所(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 130px !important;
  z-index: 5 !important;
}

//取引先(固定 ヘッダ 初期表示)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed4 {
  position: sticky !important;
  left: 320px !important;
  z-index: 6 !important;
}

//取引先(固定 ヘッダ 検索時)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed-search4 {
  position: sticky !important;
  left: 210px !important;
  z-index: 6 !important;
}

//取引先(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(3) {
  position: sticky !important;
  left: 210px !important;
  z-index: 5 !important;
}

//品目(固定 ヘッダ 初期表示)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed5 {
  position: sticky !important;
  left: 440px !important;
  z-index: 6 !important;
}

//品目(固定 ヘッダ 検索時)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed-search5 {
  position: sticky !important;
  left: 340px !important;
  z-index: 6 !important;
}

//品目(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(4) {
  position: sticky !important;
  left: 340px !important;
  z-index: 5 !important;
}

//tableヘッダ情報下寄せ
#listData.v-data-table--fixed-header ::v-deep th {
  vertical-align: bottom;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper {
  max-height: 562px;
}

::v-deep .negative-inventory {
  color: #dd0000;
}

.pbox {
  display: flex;
  justify-content: space-between;
  // border: solid 1px black;
  // padding: 5px;
}

.inout {
  // border: solid 1px black;
  vertical-align: middle;
  text-align: center;
  // width: 100px;
  // height: 100px;
  // line-height: 100px;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(5) {
  background-color: lightgray;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(6) {
  background-color: lightgray;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(7) {
  background-color: lightgray;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(9) {
  // position: sticky !important;
  // left: 250px !important;
  // z-index: 5 !important;
  background-color: lightblue;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(10) {
  // position: sticky !important;
  // left: 250px !important;
  // z-index: 5 !important;
  background-color: lightblue;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(11) {
  // position: sticky !important;
  // left: 250px !important;
  // z-index: 5 !important;
  background-color: lightblue;
}
</style>
