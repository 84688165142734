<template>
  <div style="background: white">
    <!-- ローディング画面 -->
    <Loading :loadingCounter="loadingCounter" />
    <!-- マスタ一括取込 -->
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_masterBatchImport" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <v-row class="search-row first-search-item">
            <!-- 営業所 -->
            <OfficeSelecter
              v-model="officeSelected"
              :isRequired="true"
              :readOnry="true"
              @loading="loadingCounter = $event"
              class="search-autocomplete first-search-item"
            />
            <span class="require" style="float: left">*</span>

            <!-- 取込種別 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="importType"
                :items="importTypeList"
                :label="$t('label.lbl_importType')"
                persistent-hint
                :hint="setType()"
                margin-bottom="0"
                pa-3
                :error-messages="importTypeCheckMsg"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>

            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ファイル -->
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
                :error-messages="importFileCheckMsg"
              >
              </v-file-input>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ボタン -->
            <v-btn class="post-btn" @click="search()">
              {{ $t("btn.btn_import") }}
            </v-btn>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取引先-->
            <div
              class="search-autocomplete first-search-item"
              v-if="(importType == '02' && !iniFlg) || (importType == '04' && !iniFlg)"
            >
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :error-messages="supplierCheckMsg"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 倉庫名/倉庫コード -->
            <div class="search-autocomplete first-search-item" v-if="importType == '03'">
              <v-autocomplete
                dense
                v-model="warehouseSelected"
                :items="warehouseList"
                :label="$t('label.lbl_warehouseName')"
                persistent-hint
                :hint="setWarehouse()"
                margin-bottom="0"
                :error-messages="warehouseCheckMsg"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <!--ボタン領域-->
              <div class="search-row-exeBtn">
                <!--一括削除ボタン領域-->
                <!-- <div class="btn-search-area" style="float: left">-->
                <!-- 一括削除ボタン -->
                <!--  <v-btn class="other-btn" @click="viewConfirmDialog('btn_batchDelete')">{{
                    $t("btn.btn_batchDelete")
                  }}</v-btn>
                </div> -->
                <!--ボタン領域-->
                <div class="btn-area">
                  <!-- ホームボタン -->
                  <v-btn class="other-btn" @click="moveHome()" v-show="iniFlg">{{
                    $t("ホーム")
                  }}</v-btn>
                  <!-- 戻るボタン -->
                  <v-btn class="other-btn" @click="returnIniMasterReg()" v-show="iniFlg">{{
                    $t("戻る")
                  }}</v-btn>
                  <!-- 登録ボタン -->
                  <v-btn
                    class="post-btn"
                    @click="viewConfirmDialog('btn_insert')"
                    v-bind:disabled="!activate"
                    >{{ $t("btn.btn_insert") }}</v-btn
                  >
                  <!-- 画面クリアボタン -->
                  <v-btn class="other-btn" @click="viewConfirmDialog('btn_clear')">{{
                    $t("btn.btn_clear")
                  }}</v-btn>
                </div>
              </div>
            </div>

            <div class="display-search-result-row">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          :items-per-page="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="580px"
          :page.sync="page"
        >
          <!-- 品番 -->
          <template v-slot:[`item.productCode`]="{ item }">
            <div class="listNo-style">
              {{ item.productCode }}<br />{{ item.accessoryProductCd }}
            </div>
          </template>
          <!-- 品名 -->
          <template v-slot:[`item.productCodeName`]="{ item }">
            <div class="listNo-style">{{ item.productCodeName }}</div>
          </template>
        </v-data-table>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(inputList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <ManageDataImportError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :roleMasterBatchAddUpdateFlg="infoDialog.roleMasterBatchAddUpdateFlg"
        :iniMasterRegistFlg="infoDialog.iniMasterRegistFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :iniMasterPageParams="infoDialog.iniMasterPageParams"
        :roleMasterParams="infoDialog.roleMasterParams"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import ManageDataImportError from "../mng/ManageDataImportError.vue";
import OfficeSelecter from "../businessComponent/OfficeSelecter";
import { cognito } from "../../assets/scripts/js/Cognito";
export default {
  name: appConfig.SCREEN_ID.P_MST_014,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    ManageDataImportError,
    OfficeSelecter,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,

    iniFlg: "false", // 初期導入フラグ
    paramClientCd: "",

    officeCd: "",
    officeName: "",
    file: {},
    loadingCounter: 0,
    importfile: {},
    // ファイル名
    retrievefileName: "",
    jigFileName: "",
    companyFileName: "",
    officeFileName: "",
    clientFileName: "",
    baseFileName: "",
    deliveryFileName: "",
    warehouseFileName: "",
    locationFileName: "",
    businessFileName: "",
    bizspecFileName: "",
    invoiceItemFileName: "",
    unitPriceGroupFileName: "",
    // url
    deleteUrl: "",
    // シート数
    sheetNum: 0,
    // 項目数
    clmNum: 0,
    // 取込データ
    output: [],
    sheetInfo: [],
    // 総件数
    totalCount: 0,
    message: "Try Preview!",
    // マスタ取込データ
    dataList1: [],
    dataList2: [],
    dataList3: [],
    dataList4: [],
    dataList5: [],
    // 取込確定フラグ
    confirmFlg: false,
    // 営業所プルダウン
    officeSelected: sessionStorage.getItem("sales_office_sid"),
    officeList: [],
    // 取込種別プルダウン
    importType: "",
    importTypeList: [],
    // 取込待機時間
    sleepTimeList: [],
    // 取込種別チェックエラーメッセージ
    importTypeCheckMsg: "",
    // 取込ファイルチェックエラーメッセージ
    importFileCheckMsg: "",
    // 取引先チェックエラーメッセージ
    supplierCheckMsg: "",
    // 倉庫名/倉庫コードプルダウン
    warehouseList: [],
    warehouseSelected: "",
    //倉庫名称チェックエラーメッセージ
    warehouseCheckMsg: "",
    // 名字
    familyName: "",
    // 名前
    givenName: "",
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    // メニュー
    openMenu: null,
    // 表示の切り替え
    activate: false,
    // ファイルチェックフラグ
    checkFlf: false,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      isOpen: false,
      // screenFlag: false,
      redMessage: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      changeFlag: false,
      homePageFlag: false,
      roleMasterBatchAddUpdateFlg: false,
      iniMasterRegistFlg: false,
      firstPageFlag: false,
      //ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
      iniMasterPageParams: {},
      roleMasterParams: {},
    },
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // パスワード
    pass: "",
    // ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "5%",
        sortable: false,
        align: "left",
      },
    ],
    inputList: [],
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      officeSelected: "",
      list: [],
    },
  }),

  created() {
    // 前画面からのパラメータ設定
    this.iniFlg = this.$route.params.iniFlg; // 初期導入フラグ
    this.importfile = this.$route.params.importfile; // 取込ファイル
    this.importType = this.$route.params.importType; // 取込種別
    this.paramClientCd = this.$route.params.clientCd; // 取引先コード(引継ぎ用)
  },
  methods: {
    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },

    /**
     * 初期表示
     */
    init() {
      this.inputList = [];
      this.officeCd = sessionStorage.getItem("office_cd");
      // 営業所取得
      this.getOfficeInfo();
      // 取引先取得
      this.getCustomInfo();
      // マスタ取得
      this.getMstCode();
      // 倉庫マスタ取得
      this.getWarehouse();

      // 初期導入の場合
      if (this.iniFlg) {
        // xミリ秒待機したのちに、取込処理
        setTimeout(this.search, 500);
      }
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      // エラー内容初期化
      this.supplierCheckMsg = i18n.tc("");
      this.importTypeCheckMsg = i18n.tc("");
      this.warehouseCheckMsg = i18n.tc("");
      this.importFileCheckMsg = i18n.tc("");

      // 取込種別の選択チェック
      if (button == "btn_batchDelete" && !this.importType) {
        this.importTypeCheckMsg = i18n.tc("check.chk_inputImportType");
        return;
      }
      // 取引先の選択チェック
      if (
        (button == "btn_batchDelete" && this.importType == "02") ||
        (button == "btn_batchDelete" && this.importType == "04") ||
        (button == "btn_batchDelete" && this.importType == "05")
      ) {
        if (!this.suppliersSelected) {
          this.supplierCheckMsg = i18n.tc("check.chk_inputSupplier");
          return;
        }
      } else if (button == "btn_batchDelete" && this.importType == "03") {
        if (!this.warehouseSelected) {
          this.warehouseCheckMsg = i18n.tc("check.chk_inputWarehouseName");
          return;
        }
      }

      switch (button) {
        case "btn_clear":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_clear")]);
          action = this.clear;

          this.ConfirmDialog.message = message;
          this.ConfirmDialog.title = appConfig.DIALOG.title;
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
          this.ConfirmDialog.okAction = action;
          break;
        case "btn_insert":
          isValidationRequired = true;
          this.insertType();
          break;
        case "btn_batchDelete":
          isValidationRequired = true;
          this.deleteType();
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        // 処理なし
      }
    },

    // 登録
    insertType() {
      let message;
      let action = () => {};
      switch (this.importType) {
        case "01":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          action = this.userRegister;
          break;
        case "02":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          action = this.itemRegister;
          break;
        case "03":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          action = this.warehouseLocationRegister;
          break;
        case "04":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          action = this.relatedPartyRegister;
          break;
        case "05":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          action = this.businessInvoiceItemRegister;
          break;
        case "06":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          action = this.unitPriceGroupRegister;
          break;
        default:
          message = "";
      }
      this.ConfirmDialog.message = message;
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },

    // 一括削除
    deleteType() {
      let message;
      let action = () => {};
      switch (this.importType) {
        case "01":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_batchDelete")]);
          this.deleteUrl = appConfig.API_URL.MST_USERFILE_DELETE;
          action = this.deleteMaster;
          break;
        case "02":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_batchDelete")]);
          this.deleteUrl = appConfig.API_URL.MST_ITEMSFILE_DELETE;
          action = this.deleteMaster;
          break;
        case "03":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_batchDelete")]);
          this.deleteUrl = appConfig.API_URL.MST_WAREHOUSELOCATIONFILE_DELETE;
          action = this.deleteMaster;
          break;
        case "04":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_batchDelete")]);
          this.deleteUrl = appConfig.API_URL.MST_RELATED_PARTIESFILE_DELETE;
          action = this.deleteMaster;
          break;
        case "05":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_batchDelete")]);
          this.deleteUrl = appConfig.API_URL.MST_BUSINESS_INVOICE_ITEM_DELETE;
          action = this.deleteMaster;
          break;
        case "06":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_batchDelete")]);
          this.deleteUrl = appConfig.API_URL.MST_UNITPRICE_GROUPFILE_DELETE;
          action = this.deleteMaster;
          break;
        default:
          message = "";
      }
      this.ConfirmDialog.message = message;
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },

    // 取込種別
    setType() {
      for (var i = 0; i < this.importTypeList.length; i++) {
        if (this.importTypeList[i].value == this.importType) {
          return this.importTypeList[i].name;
        }
      }
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].text;
        }
      }
    },

    /**
     * 倉庫リストボックス取得処理
     */
    setWarehouse() {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == this.warehouseSelected) {
          return this.warehouseList[i].name;
        }
      }
    },

    /**
     * 画面クリア押下
     */
    clear() {
      this.confirmFlg = false;
      this.inputList = [];
      this.importType = "";
      this.importfile = null;
      this.totalCount = 0;
      this.activate = false;
      // ボタン識別フラグ（falseだったら、画面クリア）
      this.confirmFlg = false;
    },

    /**
     * 取込前のバリデーションチェック
     */
    search() {
      // 初期化
      this.inputList = [];
      this.totalCount = 0;
      this.dataList1 = [];
      this.dataList2 = [];
      this.dataList3 = [];
      this.dataList4 = [];
      this.dataList5 = [];

      //エラー内容初期化
      this.importTypeCheckMsg = i18n.tc("");
      this.importFileCheckMsg = i18n.tc("");
      this.supplierCheckMsg = i18n.tc("");
      this.warehouseCheckMsg = i18n.tc("");

      // 取込種別と取込ファイルが選択されていない場合
      if (!this.importType && (this.importfile == null || this.importfile.name == null)) {
        this.importTypeCheckMsg = i18n.tc("check.chk_inputImportType");
        this.importFileCheckMsg = i18n.tc("check.chk_select");
        return;
      }
      // 取込種別が選択されていない場合
      if (!this.importType) {
        this.importTypeCheckMsg = i18n.tc("check.chk_inputImportType");
        return;
      }
      // 取込ファイルが選択されていない場合
      if (this.importfile == null || this.importfile.name == null) {
        this.importFileCheckMsg = i18n.tc("check.chk_select");
        return;
      }

      // 取引先の選択チェック
      if ((this.importType == "02" && !this.iniFlg) || (this.importType == "04" && !this.iniFlg)) {
        if (!this.suppliersSelected) {
          this.supplierCheckMsg = i18n.tc("check.chk_inputSupplier");
          return;
        }
      }

      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取込確定確認POPUP処理
      this.excelImport();
      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * ファイル読込
     */
    excelImport() {
      // ファイル拡張子をチェック
      let fileType = this.importfile.type;

      if (
        fileType != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        fileType != "application/vnd.ms-excel"
      ) {
        this.infoDialog.message = i18n.tc("check.chk_limitExcelFileType");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
      const xlsx = require("xlsx");

      new Promise((resolve) => {
        var reader = new FileReader();

        reader.onload = (event) => {
          var lines = event.target.result;
          var wb;
          var arr = fixdata(lines);
          wb = xlsx.read(btoa(arr), {
            type: "base64",
            cellDates: true,
          });

          // シート数の取得
          this.sheetNum = wb.SheetNames.length;
          // シート名の取得
          this.sheetInfo = wb.SheetNames;
          // シート領域の取得
          this.sheetClmNum = wb.Sheets;
          for (let i = 0; i < wb.SheetNames.length; i++) {
            var sheet = wb.SheetNames;
            // データ領域が取得できない場合
            var range = wb.Sheets[sheet[i]]["!ref"];
            if (range == undefined) {
              this.checkImportFile();
              return;
            }

            // 書式が入っている場合!refの参照が入っている場合があるため最初セルのプロパティの存在確認をする
            var firstCell = range.substr(0, range.indexOf(":"));
            if (wb.Sheets[sheet[i]][firstCell] == undefined) {
              this.checkImportFile();
              return;
            }

            // データ領域の取得
            var endCel = range.substr(range.indexOf(":") + 1);
            const cellInfo = xlsx.utils.decode_cell(endCel);
            const endRow = cellInfo.r;
            const endClm = cellInfo.c;

            for (let row = 1; row <= endRow; row++) {
              for (let clm = 0; clm <= endClm; clm++) {
                const address = xlsx.utils.encode_cell({ r: row, c: clm });
                const cell = wb.Sheets[wb.SheetNames[i]][address];
                // 空白の場合は、ブランクを設定
                if (!cell) {
                  wb.Sheets[wb.SheetNames[i]][address] = {
                    t: "s",
                    v: "",
                    r: '<t></t><phoneticPr fontId="1"/>',
                    h: "",
                    w: "",
                  };
                } else if (cell.w == "#VALUE!") {
                  wb.Sheets[wb.SheetNames[i]][address] = {
                    t: "s",
                    v: "#VALUE!",
                    r: '<t>#VALUE!</t><phoneticPr fontId="1"/>',
                    h: "#VALUE!",
                    w: "#VALUE!",
                  };
                }
              }
            }

            this.output = to_json(wb); // JSON形式のデータを格納
          }
          resolve(event.target.result);
        };
        reader.readAsArrayBuffer(this.importfile);

        function fixdata(lines) {
          var o = "",
            l = 0,
            w = 10240;
          for (; l < lines.byteLength / w; ++l) {
            o += String.fromCharCode.apply(null, new Uint8Array(lines.slice(l * w, l * w + w)));
          }
          o += String.fromCharCode.apply(null, new Uint8Array(lines.slice(l * w)));
          return o;
        }

        function to_json(data) {
          var result = {};
          data.SheetNames.forEach(function (sheetName) {
            var roa = xlsx.utils.sheet_to_json(data.Sheets[sheetName], {
              raw: true,
            });
            if (roa.length > 0) {
              result[sheetName] = roa;
            }
          });
          return result;
        }

        reader.onerror = (e) => {
          console.log("reader.error: " + reader.error);
          if (e.target.error != null) {
            this.infoDialog.message = i18n.tc("check.chk_reSelectExcel");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.importfile = null;
          }
        };
      }).then(() => {
        // 取込データ取得
        this.getRetrieveData();
        // 取込ファイルチェック
        if (!this.checkFlf) {
          this.checkImportFile();
          this.checkFlf = false;
        }
      });
    },

    /**
     * 取込データ取得
     */
    getRetrieveData() {
      let chengeArrangement = {};
      var dataList = [];
      var excelDataList1 = [];
      var excelDataList2 = [];
      var excelDataList3 = [];
      var excelDataList4 = [];
      var excelDataList5 = [];
      var warehouseLocationSheet = "倉庫マスタロケーションマスタ基本コード";
      var relatedPartySheet =
        "会社マスタ関係先マスタ_取引先関係先マスタ_拠点路線業者マスタ_基本コード";
      var itemJigSheet = "商品マスタ治具マスタ基本コード";
      var businessInvoiceItemSheet = "業務マスタ_G業務仕様マスタ_G請求項目マスタ";

      // シート名のチェック
      for (let i = 0; i < this.sheetNum; i++) {
        // 取込種別がユーザーマスタの場合
        if (this.importType == "01") {
          if (this.sheetInfo[i] == "ユーザマスタ") {
            for (let j = 2; j < this.output[this.sheetInfo[i]].length; j++) {
              chengeArrangement = this.output[this.sheetInfo[i]][j];
              dataList = Object.values(chengeArrangement);
              excelDataList1.push(dataList);
            }
            this.dataList1 = excelDataList1;
          } else {
            this.infoDialog.message = i18n.tc("check.chk_retrieveSheetName");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.checkFlf = true;
            return;
          }
        } else if (this.importType == "02") {
          if (itemJigSheet.indexOf(this.sheetInfo[i]) > -1) {
            if (this.sheetInfo[i] == "商品マスタ") {
              for (let j = 8; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList1.push(dataList);
              }
              this.dataList1 = excelDataList1;
            } else if (this.sheetInfo[i] == "治具マスタ") {
              for (let j = 8; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList2.push(dataList);
              }
              this.dataList2 = excelDataList2;
            } else if (this.sheetInfo[i] == "基本コード") {
              //なにもしない
            }
            this.checkFlf = false;
          } else {
            this.infoDialog.message = i18n.tc("check.chk_retrieveSheetName");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.checkFlf = true;
            return;
          }
        } else if (this.importType == "03") {
          if (warehouseLocationSheet.indexOf(this.sheetInfo[i]) > -1) {
            if (this.sheetInfo[i] == "倉庫マスタ") {
              for (let j = 8; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList1.push(dataList);
              }
              this.dataList1 = excelDataList1;
            } else if (this.sheetInfo[i] == "ロケーションマスタ") {
              for (let j = 8; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList2.push(dataList);
              }
              this.dataList2 = excelDataList2;
            } else if (this.sheetInfo[i] == "基本コード") {
              //なにもしない
            }
            this.checkFlf = false;
          } else {
            this.infoDialog.message = i18n.tc("check.chk_retrieveSheetName");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.checkFlf = true;
            return;
          }
        } else if (this.importType == "04") {
          if (relatedPartySheet.indexOf(this.sheetInfo[i]) > -1) {
            if (this.sheetInfo[i] == "会社マスタ") {
              for (let j = 8; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList1.push(dataList);
              }
              this.dataList1 = excelDataList1;
            } else if (this.sheetInfo[i] == "関係先マスタ_取引先") {
              for (let j = 8; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList3.push(dataList);
              }
              this.dataList3 = excelDataList3;
            } else if (this.sheetInfo[i] == "関係先マスタ_拠点") {
              for (let j = 8; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList4.push(dataList);
              }
              this.dataList4 = excelDataList4;
            } else if (this.sheetInfo[i] == "路線業者マスタ") {
              for (let j = 8; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList5.push(dataList);
              }
              this.dataList5 = excelDataList5;
            } else if (this.sheetInfo[i] == "基本コード") {
              //なにもしない
            }
            this.checkFlf = false;
          } else {
            this.infoDialog.message = i18n.tc("check.chk_retrieveSheetName");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.checkFlf = true;
            return;
          }
        } else if (this.importType == "05") {
          if (businessInvoiceItemSheet.indexOf(this.sheetInfo[i]) > -1) {
            if (this.sheetInfo[i] == "業務マスタ") {
              for (let j = 2; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList1.push(dataList);
              }
              this.dataList1 = excelDataList1;
            } else if (this.sheetInfo[i] == "G業務仕様マスタ") {
              for (let j = 2; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList2.push(dataList);
              }
              this.dataList2 = excelDataList2;
            } else if (this.sheetInfo[i] == "G請求項目マスタ") {
              for (let j = 2; j < this.output[this.sheetInfo[i]].length; j++) {
                chengeArrangement = this.output[this.sheetInfo[i]][j];
                dataList = Object.values(chengeArrangement);
                excelDataList3.push(dataList);
              }
              this.dataList3 = excelDataList3;
            }
            this.checkFlf = false;
          } else {
            this.infoDialog.message = i18n.tc("check.chk_retrieveSheetName");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.checkFlf = true;
            return;
          }
        } else if (this.importType == "06") {
          if (this.sheetInfo[i] == "単価Grマスタ") {
            for (let j = 2; j < this.output[this.sheetInfo[i]].length; j++) {
              chengeArrangement = this.output[this.sheetInfo[i]][j];
              dataList = Object.values(chengeArrangement);
              excelDataList1.push(dataList);
            }
            this.dataList1 = excelDataList1;
            this.checkFlf = false;
          } else {
            this.infoDialog.message = i18n.tc("check.chk_retrieveSheetName");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.checkFlf = true;
            return;
          }
        }
      }
    },

    /**
     * マスタコード取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取込種別
      const importTypeList = getParameter.getCodeMst(appConfig.CODETYPE.IMPORT_CATEGORY);
      // 取込待機時間
      const sleepTimeList = getParameter.getCodeMst(appConfig.CODETYPE.SLEEP_TIME);

      Promise.all([importTypeList, sleepTimeList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.importTypeList = result[0];
          this.sleepTimeList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 営業所プルダウン
    getOfficeInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getOfficeInfo() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.relatedPartyLanguage[0].relatedPartySid ?? "",
                    name: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                  });
                }
              });
              this.officeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 営業所リストボックス取得処理
     */
    setOffice() {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == this.officeSelected) {
          return this.officeList[i].name;
        }
      }
    },

    /**
     * 取引先取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getCustomInfo() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid, // 取引先Sid
                  officeCd: row.clientCd, // 取引先Cd
                });
              });
              this.supplierList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 取込ファイルチェック
     */
    checkImportFile() {
      // ファイルサイズ取得
      let fileSize = this.importfile.size;
      // ファイルサイズ上限チェック
      if (fileSize == 7340032 || fileSize > 7340032) {
        this.infoDialog.message = i18n.tc("check.chk_limitFileSize");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
      // ファイル取込件数チェック
      if (this.importType == "01" && this.dataList1.length == 0) {
        this.infoDialog.message = i18n.tc("check.chk_emptyFile");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
      // ファイル取込件数チェック
      if (this.importType == "02") {
        let dataNum = this.dataList1.length + this.dataList2.length;
        if (dataNum == 0) {
          this.infoDialog.message = i18n.tc("check.chk_emptyFile");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
      }
      // ファイル取込件数チェック
      if (this.importType == "03") {
        let dataNum = this.dataList1.length + this.dataList2.length;
        if (dataNum == 0) {
          this.infoDialog.message = i18n.tc("check.chk_emptyFile");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
      }
      // ファイル取込件数チェック
      if (this.importType == "04") {
        let dataNum =
          this.dataList1.length +
          this.dataList2.length +
          this.dataList3.length +
          this.dataList4.length +
          this.dataList5.length;
        if (dataNum == 0) {
          this.infoDialog.message = i18n.tc("check.chk_emptyFile");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }

        if (this.dataList1.length > 1 || this.dataList3.length > 1) {
          this.infoDialog.message = i18n.tc("check.chk_related_party_duplicate");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
      }

      // ファイル取込件数チェック
      if (this.importType == "05") {
        let dataNum = this.dataList1.length + this.dataList2.length + this.dataList3.length;
        if (dataNum == 0) {
          this.infoDialog.message = i18n.tc("check.chk_emptyFile");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
      }
      //ファイル取込件数チェック
      if (this.importType == "06" && this.dataList1.length == 0) {
        this.infoDialog.message = i18n.tc("check.chk_emptyFile");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
      // ファイル項目数チェック(ユーザマスタ)
      if (this.importType == "01") {
        let userClmNum = "";
        userClmNum = this.getClmNum(this.sheetClmNum["ユーザマスタ"]["!ref"]);
        if (userClmNum != 6) {
          this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", ["ユーザマスタ"]);
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
      }
      // ファイル項目数チェック(商品マスタ)
      if (this.importType == "02") {
        let itemClmNum = "";
        let jigClmNum = "";
        if (this.sheetInfo.includes("商品マスタ")) {
          itemClmNum = this.getClmNum(this.sheetClmNum["商品マスタ"]["!ref"]);
          if (itemClmNum != 30) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", ["商品マスタ"]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
        if (this.sheetInfo.includes("治具マスタ")) {
          jigClmNum = this.getClmNum(this.sheetClmNum["治具マスタ"]["!ref"]);
          if (jigClmNum != 8) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", ["治具マスタ"]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
      }
      // ファイル項目数チェック(倉庫・ロケーションマスタ)
      if (this.importType == "03") {
        let warehouseClmNum = "";
        let locationClmNum = "";
        if (this.sheetInfo.includes("倉庫マスタ")) {
          warehouseClmNum = this.getClmNum(this.sheetClmNum["倉庫マスタ"]["!ref"]);
          if (warehouseClmNum != 11) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", ["倉庫マスタ"]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
        if (this.sheetInfo.includes("ロケーションマスタ")) {
          locationClmNum = this.getClmNum(this.sheetClmNum["ロケーションマスタ"]["!ref"]);
          if (locationClmNum != 11) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", [
              "ロケーションマスタ",
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
      }
      // ファイル項目数チェック(関係先マスタ)
      if (this.importType == "04") {
        let companyClmNum = "";
        let supplierClmNum = "";
        let baseClmNum = "";
        let deliveryClmNum = "";
        if (this.sheetInfo.includes("会社マスタ")) {
          companyClmNum = this.getClmNum(this.sheetClmNum["会社マスタ"]["!ref"]);
          if (companyClmNum != 3) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", ["会社マスタ"]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
        if (this.sheetInfo.includes("関係先マスタ_取引先")) {
          supplierClmNum = this.getClmNum(this.sheetClmNum["関係先マスタ_取引先"]["!ref"]);
          if (supplierClmNum != 7) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", [
              "関係先マスタ_取引先",
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
        if (this.sheetInfo.includes("関係先マスタ_拠点")) {
          baseClmNum = this.getClmNum(this.sheetClmNum["関係先マスタ_拠点"]["!ref"]);
          if (baseClmNum != 10) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", [
              "関係先マスタ_拠点",
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
        if (this.sheetInfo.includes("路線業者マスタ")) {
          deliveryClmNum = this.getClmNum(this.sheetClmNum["路線業者マスタ"]["!ref"]);
          if (deliveryClmNum != 6) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", [
              "路線業者マスタ",
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
      }
      // ファイル項目数チェック(請求先マスタ)
      if (this.importType == "05") {
        let businessClmNum = "";
        let gbusinessSpecificationsClmNum = "";
        let gInvoiceItemClmNum = "";

        if (this.sheetInfo.includes("業務マスタ")) {
          businessClmNum = this.getClmNum(this.sheetClmNum["業務マスタ"]["!ref"]);
          if (businessClmNum != 8) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", ["業務マスタ"]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
          if (!this.headerInputCheck("業務マスタ", 3, 8)) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", ["業務マスタ"]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
        if (this.sheetInfo.includes("G業務仕様マスタ")) {
          gbusinessSpecificationsClmNum = this.getClmNum(
            this.sheetClmNum["G業務仕様マスタ"]["!ref"]
          );
          if (gbusinessSpecificationsClmNum != 56) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", [
              "G業務仕様マスタ",
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
          if (!this.headerInputCheck("G業務仕様マスタ", 3, 56)) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", [
              "G業務仕様マスタ",
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
        if (this.sheetInfo.includes("G請求項目マスタ")) {
          gInvoiceItemClmNum = this.getClmNum(this.sheetClmNum["G請求項目マスタ"]["!ref"]);
          if (gInvoiceItemClmNum != 35) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", [
              "G請求項目マスタ",
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
          if (!this.headerInputCheck("G請求項目マスタ", 3, 35)) {
            this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", [
              "G請求項目マスタ",
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
      }
      // ファイル項目数チェック(単価Grマスタ)
      if (this.importType == "06") {
        if (this.dataList1.length == 0) {
          this.infoDialog.message = i18n.tc("check.chk_emptyFile");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
        let unitPriceGrClmNum = "";
        unitPriceGrClmNum = this.getClmNum(this.sheetClmNum["単価Grマスタ"]["!ref"]);
        if (unitPriceGrClmNum != 2 || !this.headerInputCheck("単価Grマスタ", 3, 2)) {
          this.infoDialog.message = messsageUtil.getMessage("P-MST-014_002_E", ["単価Grマスタ"]);
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
      }
      // ファイル取込
      if (this.importType == "01") {
        this.retrieveUser();
      } else if (this.importType == "02") {
        this.retrieveItem();
      } else if (this.importType == "03") {
        this.retrieveWarehouseLocation();
      } else if (this.importType == "04") {
        this.retrieveRelatedParty();
      } else if (this.importType == "05") {
        this.retrieveBusinessInvoiceItem();
      } else if (this.importType == "06") {
        this.retrieveUnitPriceGroup();
      }
    },

    // シート項目数の取得
    getClmNum(value) {
      const xlsx = require("xlsx");
      const endCel = value.substr(value.indexOf(":") + 1);
      const cellInfo = xlsx.utils.decode_cell(endCel);
      const endClm = cellInfo.c;
      this.clmNum = endClm + 1;
      return this.clmNum;
    },

    /**
     * マスタ一括削除
     */
    deleteMaster() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // 取込種別
      body.reqIdv.retrieveType = this.importType;
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 倉庫SID
      body.reqIdv.warehouseSid = this.warehouseSelected;
      // console.debug("deleteMaster() Body", body);
      console.log("deleteMaster() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(this.deleteUrl, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("deleteMaster() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pass = jsonData.resIdv.pass;
              this.infoDialog.message = messsageUtil.getMessage("P-MST-014_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // 画面遷移ボタン TODO：修正する
              this.infoDialog.homePageFlag = true;
              this.infoDialog.firstPageFlag = false;
              this.clearFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;

              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.clear();
          });
      });
    },

    // 倉庫プルダウン
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      if (this.officeSelected) {
        config.params.officeSid = this.officeSelected;
      }
      config.params.isMst = "1";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSLIST, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: `${row.warehouseLanguage[0].warehouseName}` + `(${row.warehouseCd})`,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                  officeName: row.officeName,
                  officeSid: row.officeSid,
                });
              });
              this.warehouseList = list;
              this.warehouseList.unshift({ name: "", text: "", value: "", officeSid: "" });
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * ユーザーマスタ取込(POST)
     */
    retrieveUser() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // Excelデータ
      body.reqIdv.listExcelData = this.dataList1;
      // console.debug("retrieveUser() Body", body);
      console.log("retrieveUser() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_USERFILE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("retrieveUser() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (jsonData.resIdv.masterUserFile.excelErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.masterUserFile.excelErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    errKey: row.keyValue,
                    row: row.row,
                    column: row.column,
                    message: row.message,
                    value: row.value,
                    errFlg: row.errFlg,
                  });
                  this.errorDetailDialogData = errorList;
                });
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 営業所名を取得
                this.officeName = this.setOffice();
                // 営業所名
                this.detailDialogData.officeSelected = this.officeName;
                // 取込種別
                this.detailDialogData.importType = this.importType;
                this.dialogDetails = true;
              } else {
                const list = [];
                //headerItems内削除
                this.headerItems.splice(0, this.headerItems.length);
                //headerItems設定
                this.tableData();

                this.headerItems.push(
                  // 社員番号
                  {
                    text: i18n.tc("label.lbl_employeeNumber"),
                    value: "employeeNo",
                    width: "15%",
                    align: "left",
                    sortable: false,
                  },
                  // ユーザー名
                  {
                    text: i18n.tc("label.lbl_userName"),
                    value: "userName",
                    width: "30%",
                    align: "left",
                    sortable: false,
                  },
                  // ユーザー名(カナ)
                  {
                    text: i18n.tc("label.lbl_userNameKana"),
                    value: "userNameKana",
                    width: "30%",
                    align: "left",
                    sortable: false,
                  },
                  // 権限
                  {
                    text: i18n.tc("label.lbl_authority"),
                    value: "authority",
                    width: "20%",
                    align: "left",
                    sortable: false,
                  }
                );

                // 画面表示項目
                jsonData.resIdv.masterUserFile.mstExcelResults.forEach((row) => {
                  let isErrFlg = false;
                  if (row.errMsg) {
                    isErrFlg = true;
                    this.activate = false;
                  }
                  list.push({
                    isErrListFlg: isErrFlg,
                    // No
                    No: row.no,
                    // 社員番号
                    employeeNo: row.userId,
                    // ユーザー名(画面表示用)
                    userName: row.familyNameKanji + "　" + row.givenNameKanji,
                    // ユーザー名カナ(画面表示用)
                    userNameKana: row.familyNameKana + "　" + row.givenNameKana,
                    // 権限
                    authority: row.roleSid,
                    // 姓
                    familyName: row.familyNameKanji,
                    // 名
                    givenName: row.givenNameKanji,
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                // 総件数取得
                this.totalCount = this.inputList.length;

                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 商品マスタ取込(POST)
     */
    retrieveItem() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // Excelデータ
      body.reqIdv.itemDataList = this.dataList1;
      // TODO-治具マスタリスト
      body.reqIdv.jigDataList = this.dataList2;

      // console.debug("retrieveItem() Body", body);
      console.log("retrieveItem() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMSFILE_TMP, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("retrieveItem() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // ファイル名
              this.retrievefileName = jsonData.resIdv.recSndFilename.recSndFileNameItem;
              this.jigFileName = jsonData.resIdv.recSndFilename.recSndFileNameJig;
              this.resultRetrieveItem();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            // ローディング画面表示OFF
            this.loadingCounter = 0;

            reject();
          });
      });
    },

    /**
     * 商品マスタ取込結果
     */
    resultRetrieveItem() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;

      // 取り込む件数に応じて待機時間を設定
      var sleepTime = this.dataList1.length * this.sleepTimeList[0].value;

      var startMsec = new Date();
      while (new Date() - startMsec < sleepTime);

      const body = this.$httpClient.createRequestBodyConfig();

      if (!this.iniFlg) {
        let str = this.setSrhSupplier();
        this.paramClientCd = str.substr(0, 7);
      }

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // ファイル名
      body.reqIdv.recSndFileNameItem = this.retrievefileName;
      body.reqIdv.recSndFileNameJig = this.jigFileName;
      // 営業所CD
      body.reqIdv.officeCd = this.officeCd;
      // 取引先CD
      body.reqIdv.clientCd = this.paramClientCd;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMSFILE_CHECK, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("retrieveItem() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (jsonData.resIdv.masterItemsFile.excelErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.masterItemsFile.excelErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    errKey: row.keyValue,
                    row: row.row,
                    column: row.column,
                    message: row.message,
                    value: row.value,
                    sheetType: row.sheetType,
                    sheetName: row.sheetName,
                  });
                  this.errorDetailDialogData = errorList;
                });
                //エラー一覧タブが開く
                //詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                //営業所名を取得
                this.officeName = this.setOffice();
                // 営業所名
                this.detailDialogData.officeSelected = this.officeName;
                // 取込種別
                this.detailDialogData.importType = this.importType;
                this.dialogDetails = true;
              } else {
                const list = [];
                //headerItems内削除
                this.headerItems.splice(0, this.headerItems.length);
                //headerItems設定
                this.tableData();

                this.headerItems.push(
                  // 取引先
                  {
                    text: i18n.tc("label.lbl_supplier"),
                    value: "supplier",
                    width: "15%",
                    sortable: false,
                    align: "left",
                  },
                  // 品番
                  {
                    text:
                      i18n.tc("label.lbl_productCode") +
                      "/" +
                      i18n.tc("label.lbl_accessoryProduct"),
                    value: "productCode",
                    width: "15%",
                    sortable: false,
                    align: "left",
                  },
                  // 品名
                  {
                    text: i18n.tc("label.lbl_productCodeName"),
                    value: "productCodeName",
                    width: "20%",
                    sortable: false,
                    align: "left",
                  },
                  // 40品目
                  {
                    text: i18n.tc("label.lbl_itemDiv"),
                    value: "item40",
                    width: "20%",
                    sortable: false,
                    align: "left",
                  },
                  // 治具コード
                  {
                    text: i18n.tc("label.lbl_jigCode"),
                    value: "jigCd",
                    width: "10%",
                    sortable: false,
                    align: "left",
                  },
                  // 治具名称
                  {
                    text: i18n.tc("label.lbl_jigName"),
                    value: "jigName",
                    width: "15%",
                    sortable: false,
                    align: "left",
                  }
                );

                // 画面表示項目
                jsonData.resIdv.masterItemsFile.mstExcelResults.forEach((row) => {
                  let isErrFlg = false;
                  if (row.errMsg) {
                    isErrFlg = true;
                    this.activate = false;
                  }
                  list.push({
                    isErrListFlg: isErrFlg,
                    // No
                    No: row.no,
                    // 取引先コード
                    supplier: row.clientCd,
                    // 品番
                    productCode: this.isEmpty(row.itemCd) ? "" : row.itemCd.trim(),
                    // 付帯
                    accessoryProductCd: this.isEmpty(row.itemSubCd) ? "" : row.itemSubCd.trim(),
                    // 品名
                    productCodeName: row.itemName,
                    // 40品目
                    item40: row.items40Name,
                    // 治具コード
                    jigCd: row.jigCd,
                    // 治具名称
                    jigName: row.jigName,
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                // 総件数取得
                this.totalCount = this.inputList.length;

                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 倉庫、ロケーションマスタ取込(POST)
     */
    retrieveWarehouseLocation() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // Excelデータ
      body.reqIdv.warehouseDataList = this.dataList1;
      body.reqIdv.locationDataList = this.dataList2;
      // 営業所コード
      body.reqIdv.officeCd = this.officeCd;
      // console.debug("retrieveWarehouseLocation() Body", body);
      console.log("retrieveWarehouseLocation() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_WAREHOUSE_LOCATIONFILE_TMP, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("retrieveWarehouseLocation() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // ファイル名
              this.warehouseFileName = jsonData.resIdv.recSndFilename.warehouseFileName;
              this.locationFileName = jsonData.resIdv.recSndFilename.locationFileName;
              this.resultWarehouseLocation();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 倉庫・ロケーションマスタ取込結果
     */
    resultWarehouseLocation() {
      // 取り込む件数に応じて待機時間を設定
      var sleepTime = this.dataList2.length * this.sleepTimeList[0].value;
      var startMsec = new Date();
      while (new Date() - startMsec < sleepTime);

      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // ファイル名
      body.reqIdv.warehouseFileName = this.warehouseFileName;
      body.reqIdv.locationFileName = this.locationFileName;
      // 営業所CD
      body.reqIdv.officeCd = this.officeCd;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.MST_WAREHOUSE_LOCATIONFILE_CHECK,
            body,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            // console.debug("resultWarehouseLocation() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (
                jsonData.resIdv.masterWarehouseLocationFile.excelErrorCheckResultList.length != 0
              ) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.masterWarehouseLocationFile.excelErrorCheckResultList.forEach(
                  (row) => {
                    errorList.push({
                      errKey: row.keyValue,
                      row: row.row,
                      column: row.column,
                      message: row.message,
                      value: row.value,
                      sheetType: row.sheetType,
                      sheetName: row.sheetName,
                    });
                    this.errorDetailDialogData = errorList;
                  }
                );
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 営業所名を取得
                this.officeName = this.setOffice();
                // 営業所名
                this.detailDialogData.officeSelected = this.officeName;
                // 取込種別
                this.detailDialogData.importType = this.importType;
                this.dialogDetails = true;
              } else {
                const list = [];
                //headerItems内削除
                this.headerItems.splice(0, this.headerItems.length);
                //headerItems設定
                this.tableData();

                this.headerItems.push(
                  // 倉庫コード(倉庫)
                  {
                    text: i18n.tc("label.lbl_warehouseCdWh"),
                    value: "warehouseCd",
                    width: "15%",
                    align: "center",
                    sortable: false,
                  },
                  // 倉庫名称
                  {
                    text: i18n.tc("label.lbl_warehouseName"),
                    value: "warehouseName",
                    width: "10%",
                    align: "center",
                    sortable: false,
                  },
                  // 倉庫コード(ロケ)
                  {
                    text: i18n.tc("label.lbl_warehouseCdLt"),
                    value: "warehouseCdLt",
                    width: "10%",
                    align: "center",
                    sortable: false,
                  },
                  // ロケ
                  {
                    text: i18n.tc("label.lbl_location"),
                    value: "location",
                    width: "10%",
                    align: "center",
                    sortable: false,
                  },
                  // ロケGr
                  {
                    text: i18n.tc("label.lbl_locationGr"),
                    value: "locationGr",
                    width: "10%",
                    align: "center",
                    sortable: false,
                  },
                  // 品質区分
                  {
                    text: i18n.tc("label.lbl_arrivalStatus"),
                    value: "arrivalStatus",
                    width: "10%",
                    align: "center",
                    sortable: false,
                  }
                );

                // 画面表示項目
                jsonData.resIdv.masterWarehouseLocationFile.mstExcelResults.forEach((row) => {
                  let isErrFlg = false;
                  if (row.errMsg) {
                    isErrFlg = true;
                    this.activate = false;
                  }
                  list.push({
                    isErrListFlg: isErrFlg,
                    // No
                    No: row.no,
                    // 倉庫コード(倉庫)
                    warehouseCd: row.warehouseCd,
                    // 倉庫名称
                    warehouseName: row.warehouseName,
                    // 倉庫コード(ロケ)
                    warehouseCdLt: row.warehouseCdLocation,
                    // ロケ
                    location: row.locationNo,
                    // ロケGr
                    locationGr: row.locationGroup,
                    // 品質区分
                    arrivalStatus: row.qualityDiv,
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                // 総件数取得
                this.totalCount = this.inputList.length;

                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 関係先マスタ取込(POST)
     */
    retrieveRelatedParty() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // Excelデータ
      body.reqIdv.compExcelData = this.dataList1;
      body.reqIdv.officeExcelData = this.dataList2;
      body.reqIdv.clientExcelData = this.dataList3;
      body.reqIdv.baseExcelData = this.dataList4;
      body.reqIdv.deliveryExcelData = this.dataList5;
      // console.debug("retrieveRelatedParty() Body", body);
      console.log("retrieveRelatedParty() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_RELATED_PARTIESFILE_TMP, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("retrieveRelatedParty() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // ファイル名
              this.companyFileName = jsonData.resIdv.recSndFilename.recSndFileNameComp;
              this.officeFileName = jsonData.resIdv.recSndFilename.recSndFileNameOffice;
              this.clientFileName = jsonData.resIdv.recSndFilename.recSndFileNameClient;
              this.baseFileName = jsonData.resIdv.recSndFilename.recSndFileNameBase;
              this.deliveryFileName = jsonData.resIdv.recSndFilename.recSndFileNameDelivery;
              this.resultRelatedParty();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 関係先マスタ取込結果
     */
    resultRelatedParty() {
      // 取り込む件数に応じて待機時間を設定
      var sleepTime = this.dataList4.length * this.sleepTimeList[0].value;
      var startMsec = new Date();
      while (new Date() - startMsec < sleepTime);

      const body = this.$httpClient.createRequestBodyConfig();

      if (!this.iniFlg) {
        let str = this.setSrhSupplier();
        this.paramClientCd = str.substr(0, 7);
      }

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // ファイル名
      body.reqIdv.recSndFileNameComp = this.companyFileName;
      body.reqIdv.recSndFileNameOffice = this.officeFileName;
      body.reqIdv.recSndFileNameClient = this.clientFileName;
      body.reqIdv.recSndFileNameBase = this.baseFileName;
      body.reqIdv.recSndFileNameDelivery = this.deliveryFileName;
      body.reqIdv.officeCd = this.officeCd;
      body.reqIdv.clientCd = this.paramClientCd;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_RELATED_PARTIESFILE_CHECK, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("resultRelatedParty() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (jsonData.resIdv.masterRelatedPartyFile.excelErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.masterRelatedPartyFile.excelErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    errKey: row.keyValue,
                    row: row.row,
                    column: row.column,
                    message: row.message,
                    value: row.value,
                    sheetType: row.sheetType,
                    sheetName: row.sheetName,
                  });
                  this.errorDetailDialogData = errorList;
                });
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 営業所名を取得
                this.officeName = this.setOffice();
                // 営業所名
                this.detailDialogData.officeSelected = this.officeName;
                // 取込種別
                this.detailDialogData.importType = this.importType;
                this.dialogDetails = true;
              } else {
                const list = [];
                //headerItems内削除
                this.headerItems.splice(0, this.headerItems.length);
                //headerItems設定
                this.tableData();

                this.headerItems.push(
                  // 営業所コード
                  {
                    text: i18n.tc("label.lbl_officeCd"),
                    value: "officeCd",
                    width: "15%",
                    sortable: false,
                    align: "left",
                  },
                  // 取引先コード
                  {
                    text: i18n.tc("label.lbl_supplierCd"),
                    value: "supplierCd",
                    width: "20%",
                    sortable: false,
                    align: "left",
                  },
                  // 拠点コード
                  {
                    text: i18n.tc("label.lbl_baseCd"),
                    value: "baseCd",
                    width: "30%",
                    sortable: false,
                    align: "left",
                  },
                  // 関係先名
                  {
                    text: i18n.tc("label.lbl_relatedPartyName"),
                    value: "relatedPartyName",
                    width: "30%",
                    sortable: false,
                    align: "left",
                  }
                );

                // 画面表示項目
                jsonData.resIdv.masterRelatedPartyFile.mstExcelResults.forEach((row) => {
                  let isErrFlg = false;
                  if (row.errMsg) {
                    isErrFlg = true;
                    this.activate = false;
                  }
                  list.push({
                    isErrListFlg: isErrFlg,
                    // No
                    No: row.no,
                    // 営業所コード
                    officeCd: row.officeCd,
                    // 取引先コード
                    supplierCd: row.clientCd,
                    // 拠点コード
                    baseCd: row.baseCd,
                    // 関係先名
                    relatedPartyName: row.relatedPartyName,
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                // 総件数取得
                this.totalCount = this.inputList.length;

                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 請求先マスタ取込（POST）
     */
    retrieveBusinessInvoiceItem() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // Excelデータ
      body.reqIdv.businessDataList = this.dataList1;
      body.reqIdv.bizspecDataList = this.dataList2;
      body.reqIdv.invoiceItemDataList = this.dataList3;

      // console.debug("retrieveBusinessInvoiceItem() Body", body);
      console.log("retrieveBusinessInvoiceItem() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_BUSINESS_INVOICE_ITEM_TMP, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("retrieveBusinessInvoiceItem() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // ファイル名
              this.businessFileName = jsonData.resIdv.recSndFilename.recSndFileNameBusiness;
              this.bizspecFileName = jsonData.resIdv.recSndFilename.recSndFileNameBizspec;
              this.invoiceItemFileName = jsonData.resIdv.recSndFilename.recSndFileNameInvoiceItem;

              this.resultRelatedBusinessInvoiceItem();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 請求先マスタ取込結果表示
     */
    resultRelatedBusinessInvoiceItem() {
      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // ファイル名
      body.reqIdv.recSndFileNameBusiness = this.businessFileName;
      body.reqIdv.recSndFileNameBizspec = this.bizspecFileName;
      body.reqIdv.recSndFileNameInvoiceItem = this.invoiceItemFileName;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_BUSINESS_INVOICE_ITEM_CHECK, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("resultRelatedBusinessInvoiceItem() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (
                jsonData.resIdv.masterBusinessInvoiceItemFile.excelErrorCheckResultList.length != 0
              ) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.masterBusinessInvoiceItemFile.excelErrorCheckResultList.forEach(
                  (row) => {
                    errorList.push({
                      errKey: row.keyValue,
                      row: row.row,
                      column: row.column,
                      message: row.message,
                      value: row.value,
                      sheetType: row.sheetType,
                      sheetName: row.sheetName,
                    });
                    this.errorDetailDialogData = errorList;
                  }
                );
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 営業所名を取得
                this.officeName = this.setOffice();
                // 営業所名
                this.detailDialogData.officeSelected = this.officeName;
                // 取込種別
                this.detailDialogData.importType = this.importType;
                this.dialogDetails = true;
              } else {
                const list = [];
                //headerItems内削除
                this.headerItems.splice(0, this.headerItems.length);
                //headerItems設定
                this.tableData();
                this.headerItems.push(
                  // 業務No
                  {
                    text: i18n.tc("label.lbl_bussiness_no"),
                    value: "businessNo",
                    width: "15%",
                    sortable: false,
                    align: "left",
                  },
                  // 業務名称
                  {
                    text: i18n.tc("label.lbl_bussiness_name"),
                    value: "businessName",
                    width: "20%",
                    sortable: false,
                    align: "left",
                  },
                  // 何期締
                  {
                    text: i18n.tc("label.lbl_what_period"),
                    value: "whatPeriod",
                    width: "10%",
                    sortable: false,
                    align: "left",
                  },
                  // 請求項目CD
                  {
                    text: i18n.tc("label.lbl_bulling_item_cd"),
                    value: "bullingItemCd",
                    width: "15%",
                    sortable: false,
                    align: "left",
                  },
                  // 請求項目名
                  {
                    text: i18n.tc("label.lbl_bulling_item_name"),
                    value: "bullingItemName",
                    width: "30%",
                    sortable: false,
                    align: "left",
                  },
                  // 倉庫CD
                  {
                    text: i18n.tc("label.lbl_warehouse_cd"),
                    value: "warehouseCd",
                    width: "10%",
                    sortable: false,
                    align: "left",
                  }
                );

                // 画面表示項目
                jsonData.resIdv.masterBusinessInvoiceItemFile.mstExcelResults.forEach((row) => {
                  let isErrFlg = false;
                  if (row.errMsg) {
                    isErrFlg = true;
                    this.activate = false;
                  }
                  list.push({
                    isErrListFlg: isErrFlg,
                    // No
                    No: row.no,
                    // 業務No
                    businessNo: row.businessNo,
                    // 業務名称
                    businessName: row.gggnak,
                    // 何期締(コードマスタから各コードの名称を取得する)
                    whatPeriod: row.whatPeriod,
                    // 請求項目CD
                    bullingItemCd: row.gkcode,
                    // 請求項目名
                    bullingItemName: row.gkkona,
                    // 倉庫CD
                    warehouseCd: row.gkkyid,
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                // 総件数取得
                this.totalCount = this.inputList.length;

                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 単価Grマスタ取込(POST)
     */
    retrieveUnitPriceGroup() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // Excelデータ
      body.reqIdv.unitPriceGroupDataList = this.dataList1;
      // console.debug("retrieveUnitPriceGroup() Body", body);
      console.log("retrieveUnitPriceGroup() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_UNITPRICE_GROUPFILE_TMP, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("retrieveUnitPriceGroup() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // ファイル名
              this.unitPriceGroupFileName =
                jsonData.resIdv.recSndFilename.recSndFileNameUnitPriceGr;
              this.resultRetrieveUnitPriceGroup();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 単価Grマスタ取込結果
     */
    resultRetrieveUnitPriceGroup() {
      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // ファイル名
      body.reqIdv.recSndFileNameUnitPriceGroup = this.unitPriceGroupFileName;
      // 営業所CD
      //body.reqIdv.officeCd = this.officeCd;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_UNITPRICE_GROUPFILE_CHECK, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("resultRetrieveUnitPriceGroup() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (jsonData.resIdv.masterUnitPriceGroupsFile.excelErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.masterUnitPriceGroupsFile.excelErrorCheckResultList.forEach(
                  (row) => {
                    errorList.push({
                      errKey: row.keyValue,
                      row: row.row,
                      column: row.column,
                      message: row.message,
                      value: row.value,
                      sheetType: row.sheetType,
                      sheetName: row.sheetName,
                    });
                    this.errorDetailDialogData = errorList;
                  }
                );
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 営業所名を取得
                this.officeName = this.setOffice();
                // 営業所名
                this.detailDialogData.officeSelected = this.officeName;
                // 取込種別
                this.detailDialogData.importType = this.importType;
                this.dialogDetails = true;
              } else {
                const list = [];
                //headerItems内削除
                this.headerItems.splice(0, this.headerItems.length);
                //headerItems設定
                this.tableData();

                this.headerItems.push(
                  // 単価Gr
                  {
                    text: i18n.tc("label.lbl_unitPriceGr"),
                    value: "unitPriceGr",
                    width: "40%",
                    sortable: false,
                    align: "left",
                  },
                  // 単価Gr名
                  {
                    text: i18n.tc("label.lbl_unitPriceGrName"),
                    value: "unitPriceGrName",
                    width: "40%",
                    sortable: false,
                    align: "left",
                  }
                );

                // 画面表示項目
                jsonData.resIdv.masterUnitPriceGroupsFile.mstExcelResults.forEach((row) => {
                  let isErrFlg = false;
                  if (row.errMsg) {
                    isErrFlg = true;
                    this.activate = false;
                  }
                  list.push({
                    isErrListFlg: isErrFlg,
                    // No
                    No: row.no,
                    // 単価Gr
                    unitPriceGr: row.unitPriceGroup,
                    // 単価Gr名
                    unitPriceGrName: row.unitPriceGroupName,
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                // 総件数取得
                this.totalCount = this.inputList.length;

                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    tableData() {
      this.headerItems.push(
        // No
        {
          text: "No",
          value: "No",
          width: "5%",
          sortable: false,
          align: "left",
        }
      );
    },

    /**
     * ユーザーマスタ登録
     */
    userRegister() {
      this.confirmFlg = true;
      if (this.confirmFlg) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        return new Promise((resolve, reject) => {
          this.addDBUser()
            .then(() => {
              this.setCognitoUser()
                .then(() => {
                  // ローディング画面表示OFF
                  this.loadingCounter = 0;
                  resolve();
                })
                .catch((ex) => {
                  // ローディング画面表示OFF
                  this.loadingCounter = 0;
                  // Cognito登録時にエラー
                  reject(ex);
                });
            })
            .catch((ex) => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              // DB登録でエラー
              reject(ex);
            });
        }).catch((ex) => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        });
      } else {
        // 画面クリア処理
        this.inputList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.activate = false;
        // バリデーションチェックをリセット
        this.$refs.vFileInput.resetValidation();
      }
    },

    /**
     * 商品マスタ登録
     */
    itemRegister() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // 取込ファイル名
      body.reqIdv.recSndFileNameItem = this.retrievefileName;
      body.reqIdv.recSndFileNameJig = this.jigFileName;
      body.reqIdv.clientCd = this.paramClientCd;
      body.reqIdv.officeCd = this.officeCd;
      //console.debug("itemRegister() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMSFILE_CREATE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("itemRegister() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pass = jsonData.resIdv.pass;
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // 画面遷移ボタン TODO：修正する
              this.infoDialog.firstPageFlag = false;
              this.clearFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              if (this.iniFlg) {
                this.infoDialog.homePageFlag = false;
                this.infoDialog.iniMasterRegistFlg = true;
                this.infoDialog.iniMasterPageParams = {
                  clientCd: this.paramClientCd,
                  masterCountUpdateFlg: true,
                };
              } else {
                this.infoDialog.homePageFlag = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              return;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.clear();
          });
      });
    },

    /**
     * 倉庫・ロケーションマスタ登録
     */
    warehouseLocationRegister() {
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // 取込ファイル名
      body.reqIdv.warehouseFileName = this.warehouseFileName;
      body.reqIdv.locationFileName = this.locationFileName;
      body.reqIdv.officeCd = this.officeCd;
      // console.debug("itemRegister() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.MST_WAREHOUSE_LOCATIONFILE_CREATE,
            body,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            // console.debug("itemRegister() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pass = jsonData.resIdv.pass;
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // 画面遷移ボタン TODO：修正する
              this.infoDialog.firstPageFlag = false;
              this.clearFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              if (this.iniFlg) {
                this.infoDialog.homePageFlag = false;
                this.infoDialog.iniMasterRegistFlg = true;
                this.infoDialog.iniMasterPageParams = {
                  clientCd: this.paramClientCd,
                  masterCountUpdateFlg: true,
                };
              } else {
                this.infoDialog.homePageFlag = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              return;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.clear();
          });
      });
    },

    /**
     * 関係先マスタ登録
     */
    relatedPartyRegister() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // ファイル名
      body.reqIdv.recSndFileNameComp = this.companyFileName;
      body.reqIdv.recSndFileNameOffice = this.officeFileName;
      body.reqIdv.recSndFileNameClient = this.clientFileName;
      body.reqIdv.recSndFileNameBase = this.baseFileName;
      body.reqIdv.recSndFileNameDelivery = this.deliveryFileName;
      body.reqIdv.officeCd = this.officeCd;
      body.reqIdv.clientCd = this.paramClientCd;
      // console.debug("relatedPartyRegister() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_RELATED_PARTIESFILE_CREATE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("relatedPartyRegister() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pass = jsonData.resIdv.pass;
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // 画面遷移ボタン TODO：修正する
              this.infoDialog.firstPageFlag = false;
              this.clearFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              if (this.iniFlg) {
                this.infoDialog.homePageFlag = false;
                this.infoDialog.iniMasterRegistFlg = true;
                this.infoDialog.iniMasterPageParams = {
                  clientCd: this.paramClientCd,
                  masterCountUpdateFlg: true,
                };
              } else {
                this.infoDialog.homePageFlag = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              return;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.clear();
          });
      });
    },

    /**
     * 請求先マスタ登録
     */
    businessInvoiceItemRegister() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // ファイル名
      body.reqIdv.recSndFileNameBusiness = this.businessFileName;
      body.reqIdv.recSndFileNameBizspec = this.bizspecFileName;
      body.reqIdv.recSndFileNameInvoiceItem = this.invoiceItemFileName;

      // console.debug("businessInvoiceItemRegister() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.MST_BUSINESS_INVOICE_ITEM_CREATE,
            body,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            // console.debug("businessInvoiceItemRegister() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pass = jsonData.resIdv.pass;
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // 画面遷移ボタン TODO：修正する
              this.infoDialog.firstPageFlag = false;
              this.clearFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              if (this.iniFlg) {
                this.infoDialog.homePageFlag = false;
                this.infoDialog.iniMasterRegistFlg = true;
                this.infoDialog.iniMasterPageParams = {
                  clientCd: this.paramClientCd,
                  masterCountUpdateFlg: true,
                };
              } else {
                this.infoDialog.homePageFlag = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              return;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.clear();
          });
      });
    },

    /**
     * 単価Grマスタ登録
     */
    unitPriceGroupRegister() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // 取込ファイル名
      body.reqIdv.recSndFileNameUnitPriceGroup = this.unitPriceGroupFileName;
      // console.debug("unitPriceGroupRegister() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_UNITPRICE_GROUPFILE_CREATE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("unitPriceGroupRegister() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pass = jsonData.resIdv.pass;
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // 画面遷移ボタン TODO：修正する
              this.infoDialog.firstPageFlag = false;
              this.clearFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              if (this.iniFlg) {
                this.infoDialog.homePageFlag = false;
                this.infoDialog.iniMasterRegistFlg = true;
                this.infoDialog.iniMasterPageParams = {
                  clientCd: this.paramClientCd,
                  masterCountUpdateFlg: true,
                };
              } else {
                this.infoDialog.homePageFlag = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              return;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.clear();
          });
      });
    },

    isErr() {
      let countErr = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isErrListFlg) {
          countErr += 1;
        }
      }
      if (countErr == 0) {
        this.activate = true;
      }
    },

    /**
     * DBに取込み情報を登録
     */
    addDBUser() {
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_014;
      // 営業所CD
      body.reqIdv.officeCd = this.officeCd;
      // 初期導入フラグ
      if (this.iniFlg) {
        body.reqIdv.initFlg = "1";
      } else {
        body.reqIdv.initFlg = "0";
      }
      //Excelデータ
      body.reqIdv.listExcelData = this.dataList1;
      // console.debug("addDBUser() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_USERFILE_CREATE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addDBUser() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pass = jsonData.resIdv.pass;
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // 画面遷移ボタン TODO：修正する
              this.infoDialog.roleMasterBatchAddUpdateFlg = true;
              if (this.iniFlg) {
                this.infoDialog.homePageFlag = false;
                this.infoDialog.roleMasterParams = {
                  iniFlg: this.iniFlg,
                  clientCd: this.paramClientCd,
                };
              } else {
                this.infoDialog.homePageFlag = true;
              }
              this.infoDialog.firstPageFlag = false;
              this.clearFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;

              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              return;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.clear();
          });
      });
    },

    /**
     * Cognitoにユーザー登録
     */
    setCognitoUser() {
      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        for (let i = 0; i < this.inputList.length; i++) {
          cognito
            // Cognitoのユーザ登録を実行
            .userRegistration(
              this.inputList[i].employeeNo,
              this.pass,
              this.inputList[i].familyName,
              this.inputList[i].givenName
            )
            // 成功時の処理です。
            .then((response) => {
              // タスクを終了します（成功）
              resolve(response);
            })
            //   // 失敗時の処理です。
            .catch((ex) => {
              // タスクを終了します（失敗）
              reject(ex);
            });
        }
      });
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
    /**
     * ヘッダー入力チェック
     */
    headerInputCheck(sheetName, rowNum, columnNum, celRegex = /(^[A-Z]{1,3}[1-3])$/) {
      console.log(this.sheetClmNum[sheetName]);
      const entries = Object.entries(this.sheetClmNum[sheetName]);
      var previousRow = 1;
      var previousColumn = 0;
      for (let i = 0; i < entries.length; i++) {
        // セル番号
        let key = entries[i][0];
        // ヘッダーセルのみを検査対象にする
        if (celRegex.test(key)) {
          let currentRow = parseInt(key[key.length - 1]);
          if (previousRow != currentRow) {
            // 仕様上想定されるヘッダ列数と値が入力されているセルの列数が一致しない場合はエラー
            if (previousColumn != columnNum) {
              return false;
            }
            // ヘッダ行の検証が終わったら検査終了
            if (rowNum < currentRow) {
              break;
            }
            previousRow = currentRow;
            previousColumn = 1;
          } else {
            // セルに値が入っていない場合はエラー
            if ((entries[i][1].v ?? "").length !== 0) {
              previousColumn += 1;
            } else {
              return false;
            }
          }
        }
      }
      if (previousColumn != columnNum) {
        return false;
      }
      return true;
    },

    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },

    /**
     * マスタ登録状況に戻る
     */
    returnIniMasterReg() {
      this.$router.push({
        name: appConfig.MENU_ID.M_INI_MASTER_REGIST,
        params: {
          // 取引先コード
          clientCd: this.paramClientCd,
          masterCountUpdateFlg: false,
        },
      });
    },

    /**
     * ホーム画面に遷移する
     */
    moveHome() {
      this.$router.push({
        name: appConfig.MENU_ID.M_HOM,
        params: {
          // 取引先コード
          clientCd: this.paramClientCd,
          iniFlg: this.iniFlg,
        },
      });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    importType() {
      this.importTypeCheckMsg = "";
      this.suppliersSelected = "";
      this.warehouseSelected = "";
    },
    importfile() {
      this.importFileCheckMsg = "";
    },
    suppliersSelected() {
      this.supplierCheckMsg = "";
    },
    warehouseSelected() {
      this.warehouseCheckMsg = "";
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.isD {
  color: $color-error !important;
}
</style>
