<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_deliveryOrderPlanAdd" />
      <!-- （画面名）搬入計画登録-->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- ユーザー取引先プルダウン-->
            <div class="serch-textbox-useritemCd first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userSuppliersSelected"
                :items="userSupplierList"
                :label="$t('label.lbl_userSupplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                clear-icon="mdi-close-circle"
                persistent-hint
                @change="changeUserSuppliersSelected"
                :rules="[rules.inputRequired]"
                pa-3
                :disabled="sortingCheck"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- アイテム一覧の表示 -->
              </v-autocomplete>
            </div>

            <span class="require">*</span>

            <!-- 納入先工場プルダウン-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userDeliveryDestination"
                :items="userDeliveryDestinationList"
                :label="$t('label.lbl_destinationyPlant')"
                :hint="setDestinationyPlant()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
                :disabled="sortingCheck"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!--ボタン領域-->
            <div class="btn-search-area" style="float: left">
              <!-- 仕分情報選択ボタン -->
              <v-btn class="get-btn" @click="getApiList()">{{ $t("btn.btn_sortSelection") }}</v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div
              class="item-spacer"
              style="margin-top: 7px; margin-left: 2rem; padding-bottom: 30px; display: none"
            >
              Cargos連携
            </div>
            <!-- Cargos連携-->
            <div style="float: left; display: none">
              <v-btn-toggle
                mandatory
                v-model="cargosSlignment"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in cargosSlignmentList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>
          </v-row>

          <v-row :style="sortingCheckRow">
            <!-- 取引先プルダウン-->
            <div class="serch-textbox-useritemCd first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="clientSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhClient()"
                class="txt-single"
                persistent-hint
                @change="changeClient"
                :search-input.sync="searchClient"
                :rules="[rules.inputRequired]"
                :disabled="sortingCheck"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- アイテム一覧の表示 -->
              </v-autocomplete>
            </div>

            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- メーカー工場 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="makerFactory"
                :label="$t('label.lbl_makerFactory')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                maxlength="7"
                clearable
                :disabled="sortingCheck"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 搬入場所-->
            <div class="search-autocomplete" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="headerDeliveryDestinationy"
                :label="$t('label.lbl_deliveryDestinationy')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :rules="[rules.inputRequired]"
                maxlength="10"
                clearable
                :disabled="sortingCheck"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 計画コード-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="headerDeliveryPlan"
                :label="$t('label.lbl_deliveryPlanCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="sortingCheck"
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 供給先コード-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="destinationCd"
                :label="$t('label.lbl_destinationCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <v-row class="search-row">
            <!-- ユーザー品番-->
            <div class="serch-textbox-useritemCd first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userProductCd"
                :items="userProductCdList"
                :label="$t('label.lbl_userProductCd')"
                class="txt-single"
                persistent-hint
                pa-3
                @change="changeUserProductCd"
                :search-input.sync="searchUserProductCd"
                :rules="[rules.inputRequired]"
                :hint="setSrhUserProduct()"
              >
                >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 基本品番-->
            <div class="search-textbox" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="baseProductCd"
                :label="$t('label.lbl_baseProductCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                disabled="disabled"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付帯品番 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="productAccessoryCd"
                :items="productAccessoryList"
                :label="$t('label.lbl_productAccessoryCd')"
                class="txt-single"
                item-value="text"
                persistent-hint
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="margin-right: 9px">&nbsp;&nbsp;&nbsp;</span>
            <!-- 収容数 -->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="9"
                v-model="containerCapacityQuantity"
                :label="$t('label.lbl_containerCapacityQuantity')"
                @click="clickPop(containerCapacityQuantity)"
                @change="changecount(containerCapacityQuantity)"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                class="input-number-modal"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>

          <v-row class="search-row">
            <div class="date-style first-search-item">
              <!-- 基準日-->
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="deliveryDate"
                :label="$t('label.lbl_refernceDate')"
                :rules="[rules.yyyymmdd]"
                @change="changeDateDelivery"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area" style="position: relative; left: 7px">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuDelivery"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryDateCal"
                  @input="dateMenuDelivery = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;</span>
            <!-- 対象期間-->
            <div class="serch-textbox-locDetail" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="targetDate"
                :label="$t('label.lbl_targetDate')"
                class="input-number-modal"
                clear-icon="mdi-close-circle"
                maxlength="2"
                clearable
                :rules="[rules.inputRequired, rules.isNumber, rules.inputNumberRange_0_30]"
              />
            </div>
            <div class="item-spacer" style="margin-top: 7px">日間</div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 開始時間 -->
            <div class="serch-textbox-locDetail" style="margin-left: 4.6rem">
              <v-autocomplete
                dense
                maxlength="2"
                v-model="startTime"
                :items="deliveryTimeList"
                :label="$t('label.lbl_starThour')"
                class="txt-single"
                item-value="text"
                persistent-hint
                pa-3
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-area" style="float: left">
                <!-- データ取得ボタン-->
                <v-btn id="btn-pack" class="post-btn" @click="getAcquisitionCheck">
                  {{ $t("btn.btn_dataAcquisition") }}
                </v-btn>
                <!-- 画面クリアボタン-->
                <v-btn id="btn-pack" class="post-btn" @click="clearScreen">
                  {{ $t("btn.btn_clear") }}
                </v-btn>
                <!-- 戻るボタン-->
                <v-btn id="btn-pack" class="post-btn" @click="getBack">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <!--登録ボタン-->
              <div class="btn-area">
                <v-btn class="post-btn" :disabled="insertdisabled" @click="openUpdateDelete()">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
        >
          <!-- 検索結果ヘッダ情報 -->
          <template v-slot:[`header.detail1`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details0 }}</div>
                <div class="headerclass2">{{ details12 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail2`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details1 }}</div>
                <div class="headerclass2">{{ details13 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail3`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details2 }}</div>
                <div class="headerclass2">{{ details14 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail4`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details3 }}</div>
                <div class="headerclass2">{{ details15 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail5`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details4 }}</div>
                <div class="headerclass2">{{ details16 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail6`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details5 }}</div>
                <div class="headerclass2">{{ details17 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail7`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details6 }}</div>
                <div class="headerclass2">{{ details18 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail8`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details7 }}</div>
                <div class="headerclass2">{{ details19 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail9`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details8 }}</div>
                <div class="headerclass2">{{ details20 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail10`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details9 }}</div>
                <div class="headerclass2">{{ details21 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail11`]="{}">
            <v-row>
              <v-col style="text-align: center">
                <div class="headerclass1">{{ details10 }}</div>
                <div class="headerclass2">{{ details22 }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.detail12`]="{}">
            <v-row style="margin: -12px 0px -12px -12px">
              <v-col style="text-align: center; padding: 12px 0px 12px 12px">
                <div class="headerclass1">{{ details11 }}</div>
                <div class="headerclass2">{{ details23 }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 検索結果ヘッダ情報 -->
          <!-- 検索結果明細情報 -->
          <template v-slot:[`item.installationDateDisplay`]="{ item }">
            <v-row>
              <v-col :style="item.installationDateStyle">
                <div>{{ item.installationDateDisplay }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.totalQuantity`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <div>{{ item.totalQuantity }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.detail1`]="{ item, index }">
            <div v-if="item.detail1flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail1css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail1"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail1)"
                @change="changecount1(item, index, item.detail1)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail1, index, 1, item.detail1time)"
              :style="item.detail1css"
            >
              {{ item.detail1 }}
            </div>
            <div v-if="item.detail13flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail13css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail13"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail13)"
                @change="changecount13(item, index, item.detail13)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail13, index, 13, item.detail13time)"
              :style="item.detail13css"
            >
              {{ item.detail13 }}
            </div>
          </template>
          <template v-slot:[`item.detail2`]="{ item, index }">
            <div v-if="item.detail2flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail2css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail2"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail2)"
                @change="changecount2(item, index, item.detail2)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail2, index, 2, item.detail2time)"
              :style="item.detail2css"
            >
              {{ item.detail2 }}
            </div>
            <div v-if="item.detail14flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail14css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail14"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail14)"
                @change="changecount14(item, index, item.detail14)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail14, index, 14, item.detail14time)"
              :style="item.detail14css"
            >
              {{ item.detail14 }}
            </div>
          </template>
          <template v-slot:[`item.detail3`]="{ item, index }">
            <div v-if="item.detail3flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail3css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail3"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail3)"
                @change="changecount3(item, index, item.detail3)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail3, index, 3, item.detail3time)"
              :style="item.detail3css"
            >
              {{ item.detail3 }}
            </div>
            <div v-if="item.detail15flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail15css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail15"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail15)"
                @change="changecount15(item, index, item.detail15)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail15, index, 15, item.detail15time)"
              :style="item.detail15css"
            >
              {{ item.detail1 }}
            </div>
          </template>
          <template v-slot:[`item.detail4`]="{ item, index }">
            <div v-if="item.detail4flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail4css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail4"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail4)"
                @change="changecount4(item, index, item.detail4)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail4, index, 4, item.detail4time)"
              :style="item.detail4css"
            >
              {{ item.detail4 }}
            </div>
            <div v-if="item.detail16flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail16css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail16"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail16)"
                @change="changecount16(item, index, item.detail16)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail16, index, 16, item.detail16time)"
              :style="item.detail16css"
            >
              {{ item.detail16 }}
            </div>
          </template>
          <template v-slot:[`item.detail5`]="{ item, index }">
            <div v-if="item.detail5flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail5css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail5"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail5)"
                @change="changecount5(item, index, item.detail5)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail5, index, 5, item.detail5time)"
              :style="item.detail5css"
            >
              {{ item.detail5 }}
            </div>
            <div v-if="item.detail17flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail17css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail17"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail17)"
                @change="changecount17(item, index, item.detail17)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail17, index, 17, item.detail17time)"
              :style="item.detail17css"
            >
              {{ item.detail1 }}
            </div>
          </template>
          <template v-slot:[`item.detail6`]="{ item, index }">
            <div v-if="item.detail6flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail6css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail6"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail6)"
                @change="changecount6(item, index, item.detail6)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail6, index, 6, item.detail6time)"
              :style="item.detail6css"
            >
              {{ item.detail6 }}
            </div>
            <div v-if="item.detail18flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail18css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail18"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail18)"
                @change="changecount18(item, index, item.detail18)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail18, index, 18, item.detail18time)"
              :style="item.detail18css"
            >
              {{ item.detail18 }}
            </div>
          </template>
          <template v-slot:[`item.detail7`]="{ item, index }">
            <div v-if="item.detail7flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail7css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail7"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail7)"
                @change="changecount7(item, index, item.detail7)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail7, index, 7, item.detail7time)"
              :style="item.detail7css"
            >
              {{ item.detail7 }}
            </div>
            <div v-if="item.detail19flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail19css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail19"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail19)"
                @change="changecount19(item, index, item.detail19)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail19, index, 19, item.detail19time)"
              :style="item.detail19css"
            >
              {{ item.detail19 }}
            </div>
          </template>
          <template v-slot:[`item.detail8`]="{ item, index }">
            <div v-if="item.detail8flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail8css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail8"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail8)"
                @change="changecount8(item, index, item.detail8)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail8, index, 8, item.detail8time)"
              :style="item.detail8css"
            >
              {{ item.detail8 }}
            </div>
            <div v-if="item.detail20flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail20css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail20"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail20)"
                @change="changecount20(item, index, item.detail20)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail20, index, 20, item.detail20time)"
              :style="item.detail20css"
            >
              {{ item.detail20 }}
            </div>
          </template>
          <template v-slot:[`item.detail9`]="{ item, index }">
            <div v-if="item.detail9flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail9css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail9"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail9)"
                @change="changecount9(item, index, item.detail9)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail9, index, 9, item.detail9time)"
              :style="item.detail9css"
            >
              {{ item.detail9 }}
            </div>
            <div v-if="item.detail21flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail21css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail21"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail21)"
                @change="changecount21(item, index, item.detail21)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail21, index, 21, item.detail21time)"
              :style="item.detail21css"
            >
              {{ item.detail21 }}
            </div>
          </template>
          <template v-slot:[`item.detail10`]="{ item, index }">
            <div v-if="item.detail10flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail10css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail10"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail10)"
                @change="changecount10(item, index, item.detail10)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail10, index, 10, item.detail10time)"
              :style="item.detail10css"
            >
              {{ item.detail10 }}
            </div>
            <div v-if="item.detail22flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail22css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail22"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail22)"
                @change="changecount22(item, index, item.detail22)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail22, index, 22, item.detail22time)"
              :style="item.detail22css"
            >
              {{ item.detail22 }}
            </div>
          </template>
          <template v-slot:[`item.detail11`]="{ item, index }">
            <div v-if="item.detail11flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail11css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail11"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail11)"
                @change="changecount11(item, index, item.detail11)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail11, index, 11, item.detail11time)"
              :style="item.detail11css"
            >
              {{ item.detail11 }}
            </div>
            <div v-if="item.detail23flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail23css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail23"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail23)"
                @change="changecount23(item, index, item.detail23)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail23, index, 23, item.detail23time)"
              :style="item.detail23css"
            >
              {{ item.detail23 }}
            </div>
          </template>
          <template v-slot:[`item.detail12`]="{ item, index }">
            <div v-if="item.detail12flg" id="table-detail1">
              <v-text-field
                class="input-number"
                :style="item.detail12css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail12"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail12)"
                @change="changecount12(item, index, item.detail12)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle1"
              @click="detailclick(item.detail12, index, 12, item.detail12time)"
              :style="item.detail12css"
            >
              {{ item.detail12 }}
            </div>
            <div v-if="item.detail24flg" id="table-detail2">
              <v-text-field
                class="input-number"
                :style="item.detail24css"
                dense
                outlined
                maxlength="9"
                v-model="item.detail24"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck]"
                @click="clickPop(item.detail24)"
                @change="changecount24(item, index, item.detail24)"
                clear-icon="mdi-close-circle"
                clearable
                hide-details="true"
              />
            </div>
            <div
              v-else
              class="displaystyle2"
              @click="detailclick(item.detail24, index, 24, item.detail24time)"
              :style="item.detail24css"
            >
              {{ item.detail24 }}
            </div>
          </template>
          <!-- 検索結果明細情報 -->
        </v-data-table>
      </v-container>

      <!-- 出荷数登録ポップアップ-->
      <v-dialog v-model="dialogDetails" :max-width="900" persistent no-click-animation>
        <deliveryOrderPlanAddDetails
          id="detailsDialig"
          :isOpenDetailDialog.sync="dialogDetails"
          :entity="detailDialogData"
          class="overflow"
          v-on:closePopup="closePopup"
          v-on:insertPopup="insertPopup"
        />
      </v-dialog>

      <!-- 対象選択ポップアップ-->
      <v-dialog v-model="dialogSelection" :max-width="700" persistent no-click-animation>
        <deliveryOrderPlanAddSelection
          id="selectionDialig"
          :isOpenDetailDialog.sync="dialogSelection"
          :entity="slectionDialogData"
          class="overflow"
          v-on:selectPopup="selectPopup"
        />
      </v-dialog>

      <!-- 仕分情報検索ポップアップ-->
      <v-dialog v-model="sortingInfodialogSearch" :max-width="2000" persistent no-click-animation>
        <deliveryOrderPlanSortingInfoList
          id="selectionDialig"
          :isOpenDetailDialog.sync="sortingInfodialogSearch"
          :entity="sortingInfoSearchDialogData"
          class="overflow"
          v-on:sortingPopup="sortingPopup"
        />
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :deodpListFlg="infoDialog.deodpListFlg"
        :deodpAddFlg="infoDialog.deodpAddFlg"
        :okAction="clearScreenOkClick"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { getParameterProcurement } from "../../assets/scripts/js/GetParameterProcurement";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import { dateUtil } from "../../assets/scripts/js/DateUtil";
import deliveryOrderPlanAddDetails from "../procurement/deliveryOrderPlanAddDetails.vue";
import deliveryOrderPlanAddSelection from "../procurement/deliveryOrderPlanAddSelection.vue";
import deliveryOrderPlanSortingInfoList from "../procurement/deliveryOrderPlanSortingInfoList.vue";

export default {
  name: appConfig.MENU_ID.BIZ_DELIVERY_INSTRUCTIONS_SEARCH,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
    deliveryOrderPlanAddDetails,
    deliveryOrderPlanAddSelection,
    deliveryOrderPlanSortingInfoList,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    check: true,
    check1: true,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortingCheck: false,
    sortingCheckRow: "margin-top: 25px",
    cargosStyle: "",
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !value ||
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      inputNumberRange_0_30: (value) => {
        if (value == "" || value == null) {
          // 値が入っていない場合はチェックなし
          return true;
        }
        if (
          0 < Number(commonUtil.zen2han(value).replaceAll(",", "")) &&
          31 > Number(commonUtil.zen2han(value).replaceAll(",", ""))
        ) {
          return true;
        } else {
          return i18n.tc("check.chk_inputNumberRange_1-30");
        }
      },
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) => {
        if (value == "" || value == null) {
          // 値が入っていない場合はチェックなし
          return true;
        } else {
          // 処理なし
        }
        if (0 < Number(commonUtil.zen2han(value).replaceAll(",", ""))) {
          return true;
        } else {
          return i18n.tc("check.chk_inputMore");
        }
      },
    },
    baseProductCd: "", //基本品番
    code: "", //品番
    containerCapacityQuantity: "1", //収容数
    targetDate: "20", //対象期間
    makerFactory: "", //メーカー工場
    userDeliveryDestination: "", //ユーザー納入先
    userDeliveryDestinationList: [], //ユーザー納入先List
    destinationCd: "", //供給先コード
    productAccessoryCd: "", //付帯品番
    productAccessoryList: [], //付帯品番List
    // 詳細ポップアップ(出荷数追加)
    dialogDetails: false,
    insertdisabled: true,
    detailDialogData: {
      deliveryDate: "",
      deliveryTime: "",
      shipmentQuantity: "",
      value: "",
      index: "",
      detailList: [],
    },
    dialogSelection: false,
    //詳細ポップアップ(対象選択)
    slectionDialogData: {
      selectionList: [],
      headerDeliveryPlan: "",
      destinationCd: "",
    },
    sortingInfodialogSearch: false,
    // 詳細ポップアップ(仕分情報検索)
    sortingInfoSearchDialogData: {
      userSuppliersSelected: "",
      userSupplierList: [],
      clientSelected: "",
      supplierList: [],
      destinationyPlant: "",
      headerDeliveryDestinationy: "",
      headerDeliveryPlan: "",
      deliveryDate: "",
      deliveryDateCal: "",
      userMakerSected: "",
      userPlant: "",
      userDeliveryDestinationList: [],
      userDeliveryDestination: "",
      makerFactory: "",
      kbn: "",
    },

    userSuppliersSelected: "", // ユーザー取引先
    userMakerSected: "01", // 検索区分(ユーザー/メーカー)
    clientSelected: "", // 取引先
    destinationyPlant: "", // 搬入先工場
    headerDeliveryDestinationy: "", //搬入場所
    headerDeliveryPlan: "", //計画コード
    deliveryDate: "", //納入日
    deliveryDateCal: "", //納入日カレンダー
    dateMenuDelivery: false,
    startTime: "00", //搬入時間From
    shipSlipNo: "", //伝票NO
    cargosSlignment: "", // 取込状態
    dataIssueDateFrom: "", //入荷予定日From
    dataIssueDateFromCal: "", //入荷予定日Fromカレンダー
    userProductCd: "", //ユーザー品番
    //パラメータ引き継ぎ用
    searchClient: "",
    searchDestination: "",
    searchUserProductCd: "",

    openMenu: null, // メニュー
    selectHintClient: "",
    selectHintDestination: "",
    productHint: "",
    inputList: [], // 一覧
    supplierList: [],
    userSupplierList: [],
    userProductCdList: [],
    deliveryTimeList: [],
    defaultData: [], // 初期データ
    userClientCd: "",

    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      homePageFlag: false,
      deodpListFlg: false,
      deodpAddFlg: false,
      outsideClickNotCloseFlg: false,
    },

    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
    backFlg: false,
    // 入力チェックエラーフラグ
    errorFlg: true,

    details0: "0時",
    details1: "1時",
    details2: "2時",
    details3: "3時",
    details4: "4時",
    details5: "5時",
    details6: "6時",
    details7: "7時",
    details8: "8時",
    details9: "9時",
    details10: "10時",
    details11: "11時",
    details12: "12時",
    details13: "13時",
    details14: "14時",
    details15: "15時",
    details16: "16時",
    details17: "17時",
    details18: "18時",
    details19: "19時",
    details20: "20時",
    details21: "21時",
    details22: "22時",
    details23: "23時",
    //初期値
    startTimebackup: 0,
    // ヘッダ
    headerItems: [
      // 搬入日
      {
        text: i18n.tc("label.lbl_deliveryDate"),
        value: "installationDateDisplay",
        width: "9%",
        align: "center",
      },
      // 出荷数量
      {
        text: i18n.tc("label.lbl_shipmentQuantity"),
        value: "totalQuantity",
        width: "7%",
        align: "center",
      },
      {
        value: "detail1",
        width: "7%",
        align: "left",
      },
      {
        value: "detail2",
        width: "7%",
        align: "left",
      },
      {
        value: "detail3",
        width: "7%",
        align: "left",
      },
      {
        value: "detail4",
        width: "7%",
        align: "left",
      },
      {
        value: "detail5",
        width: "7%",
        align: "left",
      },
      {
        value: "detail6",
        width: "7%",
        align: "left",
      },
      {
        value: "detail7",
        width: "7%",
        align: "left",
      },
      {
        value: "detail8",
        width: "7%",
        align: "left",
      },
      {
        value: "detail9",
        width: "7%",
        align: "left",
      },
      {
        value: "detail10",
        width: "7%",
        align: "left",
      },
      {
        value: "detail11",
        width: "7%",
        align: "left",
      },
      {
        value: "detail12",
        width: "7%",
        align: "left",
      },
    ],
    dummy_input: {
      shipSlipNo: "3044021401580",
      shipmentQuantity: "1,000",
      howtoRegister: "自動",
      displayFlg: false,
    },
    dummy_input1: {
      shipSlipNo: "3044025601801",
      shipmentQuantity: "300",
      howtoRegister: "CSV",
      displayFlg: false,
    },
    dummy_input2: {
      shipSlipNo: "3044025601802",
      shipmentQuantity: "2,500",
      howtoRegister: "自動",
      displayFlg: false,
    },
    dummy_input3: {
      shipSlipNo: "3044025601803",
      shipmentQuantity: "40",
      howtoRegister: "CSV",
      displayFlg: false,
    },
    input_data: {
      shipSlipNo: "",
      shipmentQuantity: "",
      howtoRegister: "",
      displayFlg: false,
    },
    defaultList: {},
    cargosSlignmentList: [],
  }),
  created() {
    this.init();
  },

  methods: {
    /**
     * 初期値
     */
    init() {
      // ローディング画面表示OFF
      this.loadingCounter = 1;
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      this.deliveryDate = dateTimeHelper.convertJST().substr(0, 10);
      this.deliveryDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      const codeList = this.getMstCode();
      Promise.all([codeList])
        .then(() => {
          // 画面の初期値を設定します。
          this.getCustomInfo();
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    getMstCode() {
      // 開始時間
      const deliveryTimeList = getParameter.getCodeMst(appConfig.CODETYPE.USER_DELIVERY_TIME);
      //Cargos連携
      const csList = getParameter.getCodeMst(appConfig.CODETYPE.LOT_DIV);

      return Promise.all([deliveryTimeList, csList])
        .then((result) => {
          this.deliveryTimeList = result[0];
          this.cargosSlignmentList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * ユーザー取引先変更時
     */
    changeUserSuppliersSelected() {
      this.userDeliveryDestination = "";
      this.userDeliveryDestinationList = [];
      this.userProductCd = "";
      this.userProductCdList = [];
      this.baseProductCd = "";
      this.productAccessoryCd = "";
      this.productAccessoryList = [];
      // ユーザー取引先が存在する場合
      if (this.userSuppliersSelected) {
        for (let i = 0; i < this.userSupplierList.length; i++) {
          if (this.userSupplierList[i].value == this.userSuppliersSelected) {
            this.userClientCd = this.userSupplierList[i].clientCd;
          }
        }
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const userDeliveryDestinationList = getParameterProcurement.getBizCarryCompCd(
          this.userClientCd,
          dateTimeHelper.convertUTC(this.deliveryDate)
        );
        return Promise.all([userDeliveryDestinationList])
          .then((result) => {
            // 画面の初期値を設定します。
            this.userDeliveryDestinationList = result[0];
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }
    },
    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.userSupplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 登録ボタン押下
     */
    openUpdateDelete() {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        //明細データ入力チェック
        var count = 0;
        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].totalQuantity != this.inputList[i].totalQuantityold) {
            count++;
          }
        }
        if (count > 0) {
          this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.confirmDialog.isOpen = true;
          this.confirmDialog.screenFlag = true;
          this.confirmDialog.changeFlag = false;
          this.confirmDialog.okAction = this.insertOrder;
        } else {
          //
          this.backFlg = false;
          this.updateDialogMessage = true;
          this.errorFlg = false;
          this.backMessage = messsageUtil.getMessage("P-SUP-002_002_E");
        }
      }
    },
    /**
     * 登録(POST)
     */
    insertOrder() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_002;
      // 搬入計画情報List
      let bodyList = [];
      // ユーザー納入先
      let userDeliveryDestination = this.userDeliveryDestinationList.find(
        (v) => v.value == this.userDeliveryDestination
      ).userClientSid;
      //ユーザー取引先
      body.reqIdv.userClientSid = this.userSuppliersSelected;
      //ユーザー納入先
      body.reqIdv.userDestinationSid = userDeliveryDestination;
      //取引先
      body.reqIdv.makerClientSid = this.clientSelected;
      //メーカー工場
      //if (this.clientSelected) {
      body.reqIdv.clientPlantCd = this.makerFactory;
      //}
      //搬入場所
      body.reqIdv.deliveryPlaceCd = this.headerDeliveryDestinationy;
      //計画コード
      //if (this.headerDeliveryPlan) {
      body.reqIdv.planCd = this.headerDeliveryPlan;
      //}
      //供給先コード
      //if (this.destinationCd) {
      body.reqIdv.supplyToCd = this.destinationCd;
      //}
      //ユーザー品番SID
      body.reqIdv.userItemSid = this.userProductCd;
      //基本品番
      //if (this.baseProductCd) {
      body.reqIdv.userItemBaseCd = this.baseProductCd;
      //}
      //付帯品番
      //if (this.productAccessoryCd) {
      body.reqIdv.userItemSubCd = this.productAccessoryCd;
      //}
      //収容数
      //if (this.containerCapacityQuantity) {
      body.reqIdv.containerCapacityQuantity = this.containerCapacityQuantity.replaceAll(",", "");
      //}
      //仕分情報選択有無
      if (this.sortingCheck) {
        body.reqIdv.sortingInformation = "2";
      } else {
        body.reqIdv.sortingInformation = "1";
      }
      //cargos連携
      //if (this.cargosSlignment) {
      body.reqIdv.cargosKbn = this.cargosSlignment.replace("0", "");
      //}
      //明細情報追加
      for (let i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].totalQuantity != this.inputList[i].totalQuantityold) {
          //1件目
          if (this.inputList[i].detail1 != this.inputList[i].detail1old) {
            if (this.inputList[i].detail1old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail1time,
                this.inputList[i].detail1List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail1time,
                this.inputList[i].detail1
              );
            }
          }
          //2件目
          if (this.inputList[i].detail2 != this.inputList[i].detail2old) {
            if (this.inputList[i].detail2old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail2time,
                this.inputList[i].detail2List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail2time,
                this.inputList[i].detail2
              );
            }
          }
          //3件目
          if (this.inputList[i].detail3 != this.inputList[i].detail3old) {
            if (this.inputList[i].detail3old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail3time,
                this.inputList[i].detail3List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail3time,
                this.inputList[i].detail3
              );
            }
          }
          //4件目
          if (this.inputList[i].detail4 != this.inputList[i].detail4old) {
            if (this.inputList[i].detail4old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail4time,
                this.inputList[i].detail4List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail4time,
                this.inputList[i].detail4
              );
            }
          }
          //5件目
          if (this.inputList[i].detail5 != this.inputList[i].detail5old) {
            if (this.inputList[i].detail5old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail5time,
                this.inputList[i].detail5List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail5time,
                this.inputList[i].detail5
              );
            }
          }
          //6件目
          if (this.inputList[i].detail6 != this.inputList[i].detail6old) {
            if (this.inputList[i].detail6old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail6time,
                this.inputList[i].detail6List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail6time,
                this.inputList[i].detail6
              );
            }
          }
          //7件目
          if (this.inputList[i].detail7 != this.inputList[i].detail7old) {
            if (this.inputList[i].detail7old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail7time,
                this.inputList[i].detail7List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail7time,
                this.inputList[i].detail7
              );
            }
          }
          //8件目
          if (this.inputList[i].detail8 != this.inputList[i].detail8old) {
            if (this.inputList[i].detail8old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail8time,
                this.inputList[i].detail8List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail8time,
                this.inputList[i].detail8
              );
            }
          }
          //9件目
          if (this.inputList[i].detail9 != this.inputList[i].detail9old) {
            if (this.inputList[i].detail9old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail9time,
                this.inputList[i].detail9List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail9time,
                this.inputList[i].detail9
              );
            }
          }
          //10件目
          if (this.inputList[i].detail10 != this.inputList[i].detail10old) {
            if (this.inputList[i].detail10old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail10time,
                this.inputList[i].detail10List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail10time,
                this.inputList[i].detail10
              );
            }
          }
          //11件目
          if (this.inputList[i].detail11 != this.inputList[i].detail11old) {
            if (this.inputList[i].detail11old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail11time,
                this.inputList[i].detail11List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail11time,
                this.inputList[i].detail11
              );
            }
          }
          //12件目
          if (this.inputList[i].detail12 != this.inputList[i].detail12old) {
            if (this.inputList[i].detail12old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail12time,
                this.inputList[i].detail12List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail12time,
                this.inputList[i].detail12
              );
            }
          }
          //13件目
          if (this.inputList[i].detail13 != this.inputList[i].detail13old) {
            if (this.inputList[i].detail13old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail13time,
                this.inputList[i].detail13List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail13time,
                this.inputList[i].detail13
              );
            }
          }
          //14件目
          if (this.inputList[i].detail14 != this.inputList[i].detail14old) {
            if (this.inputList[i].detail14old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail14time,
                this.inputList[i].detail14List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail14time,
                this.inputList[i].detail14
              );
            }
          }
          //15件目
          if (this.inputList[i].detail15 != this.inputList[i].detail15old) {
            if (this.inputList[i].detail15old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail15time,
                this.inputList[i].detail15List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail15time,
                this.inputList[i].detail15
              );
            }
          }
          //16件目
          if (this.inputList[i].detail16 != this.inputList[i].detail16old) {
            if (this.inputList[i].detail16old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail16time,
                this.inputList[i].detail16List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail16time,
                this.inputList[i].detail16
              );
            }
          }
          //17件目
          if (this.inputList[i].detail17 != this.inputList[i].detail17old) {
            if (this.inputList[i].detail17old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail17time,
                this.inputList[i].detail17List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail17time,
                this.inputList[i].detail17
              );
            }
          }
          //18件目
          if (this.inputList[i].detail18 != this.inputList[i].detail18old) {
            if (this.inputList[i].detail18old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail18time,
                this.inputList[i].detail18List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail18time,
                this.inputList[i].detail18
              );
            }
          }
          //19件目
          if (this.inputList[i].detail19 != this.inputList[i].detail19old) {
            if (this.inputList[i].detail19old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail19time,
                this.inputList[i].detail19List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail19time,
                this.inputList[i].detail19
              );
            }
          }
          //20件目
          if (this.inputList[i].detail20 != this.inputList[i].detail20old) {
            if (this.inputList[i].detail20old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail20time,
                this.inputList[i].detail20List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail20time,
                this.inputList[i].detail20
              );
            }
          }
          //21件目
          if (this.inputList[i].detail21 != this.inputList[i].detail21old) {
            if (this.inputList[i].detail21old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail21time,
                this.inputList[i].detail21List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail21time,
                this.inputList[i].detail21
              );
            }
          }
          //22件目
          if (this.inputList[i].detail22 != this.inputList[i].detail22old) {
            if (this.inputList[i].detail22old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail22time,
                this.inputList[i].detail22List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail22time,
                this.inputList[i].detail22
              );
            }
          }
          //23件目
          if (this.inputList[i].detail23 != this.inputList[i].detail23old) {
            if (this.inputList[i].detail23old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail23time,
                this.inputList[i].detail23List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail23time,
                this.inputList[i].detail23
              );
            }
          }
          //24件目
          if (this.inputList[i].detail24 != this.inputList[i].detail24old) {
            if (this.inputList[i].detail24old != "") {
              bodyList = this.detailInsertDaialog(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail24time,
                this.inputList[i].detail24List
              );
            } else {
              bodyList = this.detailInsert(
                bodyList,
                this.inputList[i].installationDate,
                this.inputList[i].detail24time,
                this.inputList[i].detail24
              );
            }
          }
        }
      }
      body.reqIdv.ibdiDtl = bodyList;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_DELIVERY_INSERT, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addRcvSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.deodpListFlg = true;
              this.infoDialog.deodpAddFlg = true;
              this.infoDialog.firstPageFlag = false;

              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.deodpListFlg = false;
        this.infoDialog.deodpAddFlg = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },

    /**
     * 明細登録用(直接入力)
     */
    detailInsert(bodyList, installationDate, time, stock) {
      var inputMap = {};
      inputMap.outListNo = "";
      inputMap.deliveryDate = installationDate.replaceAll("/", "");
      inputMap.deliveryTime = time.replace(":", "");
      inputMap.stock = stock.replaceAll(",", "");
      bodyList.push(inputMap);
      return bodyList;
    },

    /**
     * 明細登録用(ダイアログ入力)
     */
    detailInsertDaialog(bodyList, installationDate, time, listDetail) {
      for (let v = 0; v < listDetail.length; v++) {
        if (listDetail[v].displayFlg) {
          var inputMap = {};
          inputMap.deliveryDate = installationDate.replaceAll("/", "");
          inputMap.deliveryTime = time.replace(":", "");
          inputMap.outListNo = listDetail[v].shipSlipNo;
          inputMap.stock = listDetail[v].shipmentQuantity.replaceAll(",", "");
          bodyList.push(inputMap);
        }
      }
      return bodyList;
    },

    /**
     * プルダウンチェンジイベント
     */
    changeClient(value) {
      // 既存品番/品名を選択時
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.selectHintClient = val.text;
        this.searchClient = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.selectHintClient = "";
      } else {
        // 処理なし
      }
    },

    changeUserProductCd(value) {
      // 既存品番/品名を選択時
      let val = this.userProductCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.searchUserProductCd = val.text;
      }
      this.userProductCdList = this.userProductCdList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHint = "";
        //付帯品番のリストと値をクリア
        this.productAccessoryCd = "";
        this.productAccessoryList = [];
        this.baseProductCd = "";
        this.code = "";
      } else {
        this.loadingCounter = 1;
        for (var i = 0; i < this.userProductCdList.length; i++) {
          if (this.userProductCdList[i].value == this.userProductCd) {
            this.code = this.userProductCdList[i].code;
            this.baseProductCd = this.userProductCdList[i].baseCd;
            break;
          }
        }
        // 付帯品番のリストを取得
        this.productAccessoryCd = "";
        const fromList = getParameter.getIncidentalItemsBizAddName(
          this.userSuppliersSelected,
          this.code,
          this.baseProductCd
        );
        Promise.all([fromList])
          .then((result) => {
            // 画面の初期値を設定します。
            this.productAccessoryList = result[0];
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }
    },
    /**
     * 戻るボタンダイアログ画面、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
      if (this.backFlg) {
        this.$router.push({
          name: appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_LIST,
          params: {
            // officeSid: this.user
            searchSuppliersSelected: this.searchSuppliersSelected,
            transitionFlg: true,
            searchuserMakerSected: this.$route.params.searchuserMakerSected,
            searchimportStatus: this.$route.params.searchimportStatus,
            searchuserSuppliersSelected: this.$route.params.searchuserSuppliersSelected,

            searchClientCd: this.$route.params.searchClientCd,
            searchMakerClientCd: this.$route.params.searchMakerClientCd,
            searchselectHintClient: this.$route.params.searchselectHintClient,
            searchsupplierList: this.$route.params.searchsupplierList,
            searchSuppliersList: this.$route.params.searchSuppliersList,
            searchclientSelected: this.$route.params.searchclientSelected,

            searchdestinationyPlant: this.$route.params.searchdestinationyPlant,
            searchheaderDeliveryDestinationy: this.$route.params.searchheaderDeliveryDestinationy,
            searchheaderDeliveryPlan: this.$route.params.searchheaderDeliveryPlan,
            searchdeliveryDate: this.$route.params.searchdeliveryDate,
            searchdeliveryDateCal: this.$route.params.searchdeliveryDateCal,
            searchdeliveryTimeFrom: this.$route.params.searchdeliveryTimeFrom,
            searchdeliveryTimeTo: this.$route.params.searchdeliveryTimeTo,
            searchshipSlipNo: this.$route.params.searchshipSlipNo,
            searchdataIssueDateFrom: this.$route.params.searchdataIssueDateFrom,
            searchdataIssueDateFromCal: this.$route.params.searchdataIssueDateFromCal,
            searchdataIssueDateTo: this.$route.params.searchdataIssueDateTo,
            searchdataIssueDateToCal: this.$route.params.searchdataIssueDateToCal,

            searchuserItemCd: this.$route.params.searchuserItemCd,
            searchproductHint: this.$route.params.searchproductHint,
            searchuserProductCdList: this.$route.params.searchuserProductCdList,
            searchDestinationyPlantList: this.$route.params.searchDestinationyPlantList,
            searchuserProductCd: this.$route.params.searchuserProductCd,
            searchuserProductSid: this.$route.params.searchuserProductSid,
            searchautoUpdateFlag: this.$route.params.searchautoUpdateFlag,
            searchautoUpdateIntervalMin: this.$route.params.searchautoUpdateIntervalMin,
            searchInputListFlag: this.$route.params.searchInputListFlag,
            searchItemsPerPageBtn: this.$route.params.searchItemsPerPageBtn,
            searchIssuedDiff: this.$route.params.searchIssuedDiff,
            btnid: this.$route.params.btnid,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
          },
        });
      }
    },
    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * データ取得ボタン押下時
     */
    getAcquisitionCheck() {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        if (this.headerDeliveryPlan != "" && this.headerDeliveryPlan != null) {
          if (this.destinationCd != "" && this.destinationCd != null) {
            this.getAcquisition();
          } else {
            this.getSelection();
          }
        } else {
          this.getSelection();
        }
      }
    },
    getSelection() {
      const config = this.$httpClient.createGetApiRequestConfig();
      // ローディング画面表示ON
      this.loadingCounter = 1;
      //APIに渡す値をconfig.paramsにセット==================================//
      //ユーザー取引先
      config.params.userClientSid = this.userSuppliersSelected;
      // ユーザー納入先SID取得
      let userDeliveryDestination = this.userDeliveryDestinationList.find(
        (v) => v.value == this.userDeliveryDestination
      ).userClientSid;
      //ユーザー納入先
      config.params.userDestinationSid = userDeliveryDestination;
      //取引先
      config.params.makerClientSid = this.clientSelected;
      //メーカー工場
      if (this.clientSelected) {
        config.params.clientPlantCd = this.makerFactory;
      }
      //搬入場所
      config.params.deliveryPlaceCd = this.headerDeliveryDestinationy;
      //計画コード
      if (this.headerDeliveryPlan) {
        config.params.planCd = this.headerDeliveryPlan;
      }
      //供給先コード
      if (this.destinationCd) {
        config.params.supplyToCd = this.destinationCd;
      }
      //基準日
      config.params.deliveryDate = this.deliveryDate;
      //APIに渡す値をconfig.paramsにセット==================================//
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_DELIVERY_PSCD_SEARCH, config)
          .then((response) => {
            // console.debug("apigetStock() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];

              this.selection = jsonData.resIdv.planSupplyToList;
              if (this.selection.length == 0) {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
                this.getAcquisition();
              } else if (this.selection.length == 1) {
                if (
                  this.selection[0].planCd != this.headerDeliveryPlan ||
                  this.selection[0].supplyToCd != this.destinationCd
                ) {
                  list.push({
                    choise: false,
                    headerDeliveryPlan: this.headerDeliveryPlan,
                    destinationCd: this.destinationCd,
                  });
                  list.push({
                    choise: false,
                    headerDeliveryPlan: this.selection[0].planCd,
                    destinationCd: this.selection[0].supplyToCd,
                  });
                  this.slectionDialogData.selectionList = list;
                  this.dialogSelection = true;
                } else {
                  // ローディング画面表示OFF
                  this.loadingCounter = 0;
                  this.getAcquisition();
                }
              } else {
                for (let i = 0; i < this.selection.length; i++) {
                  list.push({
                    choise: false,
                    headerDeliveryPlan: this.selection[i].planCd,
                    destinationCd: this.selection[i].supplyToCd,
                  });
                }
                this.slectionDialogData.selectionList = list;
                this.dialogSelection = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetStock() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    getAcquisition() {
      const config = this.$httpClient.createGetApiRequestConfig();
      // ローディング画面表示ON
      this.loadingCounter = 1;
      //APIに渡す値をconfig.paramsにセット==================================//
      //ユーザー取引先
      config.params.userClientSid = this.userSuppliersSelected;
      // ユーザー納入先SID取得
      let userDeliveryDestination = this.userDeliveryDestinationList.find(
        (v) => v.value == this.userDeliveryDestination
      ).userClientSid;
      //ユーザー納入先
      config.params.userDestinationSid = userDeliveryDestination;
      //取引先
      config.params.makerClientSid = this.clientSelected;
      //メーカー工場
      config.params.clientPlantCd = this.makerFactory;
      //搬入場所
      config.params.deliveryPlaceCd = this.headerDeliveryDestinationy;
      //計画コード
      config.params.planCd = this.headerDeliveryPlan;
      //供給先コード
      config.params.supplyToCd = this.destinationCd;
      //基準日
      config.params.deliveryDate = this.deliveryDate;
      //対象期間
      config.params.targetPeriod = this.targetDate;
      //ユーザー品番SID
      config.params.userItemSid = this.userProductCd;
      //基本品番
      config.params.userItemBaseCd = this.baseProductCd;
      //付帯品番
      config.params.userItemSubCd = this.productAccessoryCd;
      //APIに渡す値をconfig.paramsにセット==================================//
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_DELIVERY_DATA_ACQUISITION, config)
          .then((response) => {
            // console.debug("apigetStock() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              const count = Number(this.startTime);
              if (this.startTimebackup != count) {
                this.startTimebackup = count;
                this.getTableHead(count);
              }
              this.insertdisabled = false;
              var installation = this.deliveryDateCal;
              var WeekChars = ["日", "月", "火", "水", "木", "金", "土"];
              let i = 0;
              //明細項目セット
              this.inputList = [];
              this.defaultList = this.getInitialization();
              this.select = jsonData.resIdv.deliveryPlanInformation;
              for (var b = 0; b < Number(this.targetDate); b++) {
                this.getCountTime(count, this.defaultList);
                var count1 = 0;
                var totalQuantityCount = 0;
                //日付取得
                var dObj = new Date(installation);
                var wDay = dObj.getDay();
                this.defaultList.No = i + 1;
                this.defaultList.installationDateDisplay =
                  this.formatDate(installation) + "(" + WeekChars[wDay] + ")";
                if (WeekChars[wDay] == "土") {
                  this.defaultList.installationDateStyle = "color: #0000cd";
                } else if (WeekChars[wDay] == "日") {
                  this.defaultList.installationDateStyle = "color: #dc143c;";
                } else {
                  this.defaultList.installationDateStyle = "";
                }
                this.defaultList.installationDate = this.formatDate(installation);
                for (; i < this.select.length; i++) {
                  console.log("this.select[i].deliveryDate" + this.select[i].deliveryDate);
                  console.log("installation" + installation);
                  if (this.select[i].deliveryDate == installation.replaceAll("-", "")) {
                    count1++;
                    //出荷数追加
                    totalQuantityCount = Number(totalQuantityCount) + Number(this.select[i].stock);
                    var inputMap = {};
                    inputMap.shipSlipNo = this.select[i].outListNo;
                    inputMap.shipmentQuantity = commonFunction.getDelimiter(
                      this.select[i].stock,
                      7
                    );
                    inputMap.displayFlg = false;
                    if (this.select[i].entryDiv == "01") {
                      inputMap.howtoRegister = "自動";
                    } else if (this.select[i].entryDiv == "01") {
                      inputMap.howtoRegister = "CSV";
                    } else {
                      inputMap.howtoRegister = "手入力";
                    }
                    var time = this.select[i].deliveryTime;
                    //時間チェック
                    if (this.defaultList.detail1time.substring(0, 2) == time.substring(0, 2)) {
                      this.defaultList.detail1 =
                        Number(this.defaultList.detail1) + Number(this.select[i].stock);
                      this.defaultList.detail1List.push(inputMap);
                      this.defaultList.detail1flg = false;
                    } else if (
                      this.defaultList.detail2time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail2 =
                        Number(this.defaultList.detail2) + Number(this.select[i].stock);
                      this.defaultList.detail2List.push(inputMap);
                      this.defaultList.detail2flg = false;
                    } else if (
                      this.defaultList.detail3time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail3 =
                        Number(this.defaultList.detail3) + Number(this.select[i].stock);
                      this.defaultList.detail3List.push(inputMap);
                      this.defaultList.detail3flg = false;
                    } else if (
                      this.defaultList.detail4time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail4 =
                        Number(this.defaultList.detail4) + Number(this.select[i].stock);
                      this.defaultList.detail4List.push(inputMap);
                      this.defaultList.detail4flg = false;
                    } else if (
                      this.defaultList.detail5time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail5 =
                        Number(this.defaultList.detail5) + Number(this.select[i].stock);
                      this.defaultList.detail5List.push(inputMap);
                      this.defaultList.detail5flg = false;
                    } else if (
                      this.defaultList.detail6time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail6 =
                        Number(this.defaultList.detail6) + Number(this.select[i].stock);
                      this.defaultList.detail6List.push(inputMap);
                      this.defaultList.detail6flg = false;
                    } else if (
                      this.defaultList.detail7time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail7 =
                        Number(this.defaultList.detail7) + Number(this.select[i].stock);
                      this.defaultList.detail7List.push(inputMap);
                      this.defaultList.detail7flg = false;
                    } else if (
                      this.defaultList.detail8time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail8 =
                        Number(this.defaultList.detail8) + Number(this.select[i].stock);
                      this.defaultList.detail8List.push(inputMap);
                      this.defaultList.detail8flg = false;
                    } else if (
                      this.defaultList.detail9time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail9 =
                        Number(this.defaultList.detail9) + Number(this.select[i].stock);
                      this.defaultList.detail9List.push(inputMap);
                      this.defaultList.detail9flg = false;
                    } else if (
                      this.defaultList.detail10time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail10 =
                        Number(this.defaultList.detail10) + Number(this.select[i].stock);
                      this.defaultList.detail10List.push(inputMap);
                      this.defaultList.detail10flg = false;
                    } else if (
                      this.defaultList.detail11time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail11 =
                        Number(this.defaultList.detail11) + Number(this.select[i].stock);
                      this.defaultList.detail11List.push(inputMap);
                      this.defaultList.detail11flg = false;
                    } else if (
                      this.defaultList.detail12time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail12 =
                        Number(this.defaultList.detail12) + Number(this.select[i].stock);
                      this.defaultList.detail12List.push(inputMap);
                      this.defaultList.detail12flg = false;
                    } else if (
                      this.defaultList.detail13time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail13 =
                        Number(this.defaultList.detail13) + Number(this.select[i].stock);
                      this.defaultList.detail13List.push(inputMap);
                      this.defaultList.detail13flg = false;
                    } else if (
                      this.defaultList.detail14time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail14 =
                        Number(this.defaultList.detail14) + Number(this.select[i].stock);
                      this.defaultList.detail14List.push(inputMap);
                      this.defaultList.detail14flg = false;
                    } else if (
                      this.defaultList.detail15time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail15 =
                        Number(this.defaultList.detail15) + Number(this.select[i].stock);
                      this.defaultList.detail15List.push(inputMap);
                      this.defaultList.detail15flg = false;
                    } else if (
                      this.defaultList.detail16time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail16 =
                        Number(this.defaultList.detail16) + Number(this.select[i].stock);
                      this.defaultList.detail16List.push(inputMap);
                      this.defaultList.detail16flg = false;
                    } else if (
                      this.defaultList.detail17time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail17 =
                        Number(this.defaultList.detail17) + Number(this.select[i].stock);
                      this.defaultList.detail17List.push(inputMap);
                      this.defaultList.detail17flg = false;
                    } else if (
                      this.defaultList.detail18time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail18 =
                        Number(this.defaultList.detail18) + Number(this.select[i].stock);
                      this.defaultList.detail18List.push(inputMap);
                      this.defaultList.detail18flg = false;
                    } else if (
                      this.defaultList.detail19time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail19 =
                        Number(this.defaultList.detail19) + Number(this.select[i].stock);
                      this.defaultList.detail19List.push(inputMap);
                      this.defaultList.detail19flg = false;
                    } else if (
                      this.defaultList.detail20time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail20 =
                        Number(this.defaultList.detail20) + Number(this.select[i].stock);
                      this.defaultList.detail20List.push(inputMap);
                      this.defaultList.detail20flg = false;
                    } else if (
                      this.defaultList.detail21time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail21 =
                        Number(this.defaultList.detail21) + Number(this.select[i].stock);
                      this.defaultList.detail21List.push(inputMap);
                      this.defaultList.detail21flg = false;
                    } else if (
                      this.defaultList.detail22time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail22 =
                        Number(this.defaultList.detail22) + Number(this.select[i].stock);
                      this.defaultList.detail22List.push(inputMap);
                      this.defaultList.detail22flg = false;
                    } else if (
                      this.defaultList.detail23time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail23 =
                        Number(this.defaultList.detail23) + Number(this.select[i].stock);
                      this.defaultList.detail23List.push(inputMap);
                      this.defaultList.detail23flg = false;
                    } else if (
                      this.defaultList.detail24time.substring(0, 2) == time.substring(0, 2)
                    ) {
                      this.defaultList.detail24 =
                        Number(this.defaultList.detail24) + Number(this.select[i].stock);
                      this.defaultList.detail24List.push(inputMap);
                      this.defaultList.detail24flg = false;
                    }
                  } else {
                    var checkDate = this.select[i].deliveryDate;
                    if (
                      dObj <
                      new Date(
                        checkDate.substring(0, 4),
                        checkDate.substring(4, 2),
                        checkDate.substring(6, 2)
                      )
                    ) {
                      break;
                    }
                  }
                }
                if (count1 > 0) {
                  //カンマ区切りに変更
                  this.defaultList.totalQuantity = commonFunction.getDelimiter(
                    totalQuantityCount,
                    7
                  );
                  this.defaultList.totalQuantityold = commonFunction.getDelimiter(
                    totalQuantityCount,
                    7
                  );
                  if (this.defaultList.detail1 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail1, 7);
                    this.defaultList.detail1 = countStock;
                    this.defaultList.detail1old = countStock;
                  }
                  if (this.defaultList.detail2 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail2, 7);
                    this.defaultList.detail2 = countStock;
                    this.defaultList.detail2old = countStock;
                  }
                  if (this.defaultList.detail3 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail3, 7);
                    this.defaultList.detail3 = countStock;
                    this.defaultList.detail3old = countStock;
                  }
                  if (this.defaultList.detail4 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail4, 7);
                    this.defaultList.detail4 = countStock;
                    this.defaultList.detail4old = countStock;
                  }
                  if (this.defaultList.detail5 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail5, 7);
                    this.defaultList.detail5 = countStock;
                    this.defaultList.detail5old = countStock;
                  }
                  if (this.defaultList.detail5 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail5, 7);
                    this.defaultList.detail5 = countStock;
                    this.defaultList.detail5old = countStock;
                  }
                  if (this.defaultList.detail6 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail6, 7);
                    this.defaultList.detail6 = countStock;
                    this.defaultList.detail6old = countStock;
                  }
                  if (this.defaultList.detail7 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail7, 7);
                    this.defaultList.detail7 = countStock;
                    this.defaultList.detail7old = countStock;
                  }
                  if (this.defaultList.detail8 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail8, 7);
                    this.defaultList.detail8 = countStock;
                    this.defaultList.detail8old = countStock;
                  }
                  if (this.defaultList.detail9 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail9, 7);
                    this.defaultList.detail9 = countStock;
                    this.defaultList.detail9old = countStock;
                  }
                  if (this.defaultList.detail10 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail10, 7);
                    this.defaultList.detail10 = countStock;
                    this.defaultList.detail10old = countStock;
                  }
                  if (this.defaultList.detail11 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail11, 7);
                    this.defaultList.detail11 = countStock;
                    this.defaultList.detail11old = countStock;
                  }
                  if (this.defaultList.detail12 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail12, 7);
                    this.defaultList.detail12 = countStock;
                    this.defaultList.detail12old = countStock;
                  }
                  if (this.defaultList.detail13 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail13, 7);
                    this.defaultList.detail13 = countStock;
                    this.defaultList.detail13old = countStock;
                  }
                  if (this.defaultList.detail14 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail14, 7);
                    this.defaultList.detail14 = countStock;
                    this.defaultList.detail14old = countStock;
                  }
                  if (this.defaultList.detail15 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail15, 7);
                    this.defaultList.detail15 = countStock;
                    this.defaultList.detail15old = countStock;
                  }
                  if (this.defaultList.detail16 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail16, 7);
                    this.defaultList.detail16 = countStock;
                    this.defaultList.detail16old = countStock;
                  }
                  if (this.defaultList.detail17 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail17, 7);
                    this.defaultList.detail17 = countStock;
                    this.defaultList.detail17old = countStock;
                  }
                  if (this.defaultList.detail18 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail18, 7);
                    this.defaultList.detail18 = countStock;
                    this.defaultList.detail18old = countStock;
                  }
                  if (this.defaultList.detail19 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail19, 7);
                    this.defaultList.detail19 = countStock;
                    this.defaultList.detail19old = countStock;
                  }
                  if (this.defaultList.detail20 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail20, 7);
                    this.defaultList.detail20 = countStock;
                    this.defaultList.detail20old = countStock;
                  }
                  if (this.defaultList.detail21 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail21, 7);
                    this.defaultList.detail21 = countStock;
                    this.defaultList.detail21old = countStock;
                  }
                  if (this.defaultList.detail22 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail22, 7);
                    this.defaultList.detail22 = countStock;
                    this.defaultList.detail22old = countStock;
                  }
                  if (this.defaultList.detail23 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail23, 7);
                    this.defaultList.detail23 = countStock;
                    this.defaultList.detail23old = countStock;
                  }
                  if (this.defaultList.detail24 != "") {
                    const countStock = commonFunction.getDelimiter(this.defaultList.detail24, 7);
                    this.defaultList.detail24 = countStock;
                    this.defaultList.detail24old = countStock;
                  }
                }
                list.push(this.defaultList);
                this.defaultList = {};
                this.defaultList = this.getInitialization();
                installation = commonFunction.addDate(installation, "add");
              }
              this.inputList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetStock() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    getInitialization() {
      var val = {
        installationDateDisplay: "", //搬入日(表示用)
        installationDate: "", //搬入日
        installationDateStyle: "",
        totalQuantity: "0", //出荷数量
        totalQuantityold: "0", //出荷数量(取得時点)
        detail1: "",
        detail1old: "",
        detail1css: "",
        detail1flg: true,
        detail1List: [],
        detail1time: "",
        detail2: "",
        detail2old: "",
        detail2css: "",
        detail3: "",
        detail3old: "",
        detail3css: "",
        detail4: "",
        detail4old: "",
        detail4css: "",
        detail5: "",
        detail5old: "",
        detail5css: "",
        detail6: "",
        detail6old: "",
        detail6css: "",
        detail7: "",
        detail7old: "",
        detail7css: "",
        detail8: "",
        detail8old: "",
        detail8css: "",
        detail9: "",
        detail9old: "",
        detail9css: "",
        detail10: "",
        detail10old: "",
        detail10css: "",
        detail11: "",
        detail11old: "",
        detail11css: "",
        detail12: "",
        detail12old: "",
        detail12css: "",
        detail13: "",
        detail13old: "",
        detail13css: "",
        detail14: "",
        detail14old: "",
        detail14css: "",
        detail15: "",
        detail15old: "",
        detail15css: "",
        detail16: "",
        detail16old: "",
        detail16css: "",
        detail17: "",
        detail17old: "",
        detail17css: "",
        detail18: "",
        detail18old: "",
        detail18css: "",
        detail19: "",
        detail19old: "",
        detail19css: "",
        detail20: "",
        detail20old: "",
        detail20css: "",
        detail21: "",
        detail21old: "",
        detail21css: "",
        detail22: "",
        detail22old: "",
        detail22css: "",
        detail23: "",
        detail23old: "",
        detail23css: "",
        detail24: "",
        detail24old: "",
        detail24css: "",
        detail2time: "",
        detail3time: "",
        detail4time: "",
        detail5time: "",
        detail6time: "",
        detail7time: "",
        detail8time: "",
        detail9time: "",
        detail10time: "",
        detail11time: "",
        detail12time: "",
        detail13time: "",
        detail14time: "",
        detail15time: "",
        detail16time: "",
        detail17time: "",
        detail18time: "",
        detail19time: "",
        detail20time: "",
        detail21time: "",
        detail22time: "",
        detail23time: "",
        detail24time: "",
        detail2flg: true,
        detail3flg: true,
        detail4flg: true,
        detail5flg: true,
        detail6flg: true,
        detail7flg: true,
        detail8flg: true,
        detail9flg: true,
        detail10flg: true,
        detail11flg: true,
        detail12flg: true,
        detail13flg: true,
        detail14flg: true,
        detail15flg: true,
        detail16flg: true,
        detail17flg: true,
        detail18flg: true,
        detail19flg: true,
        detail20flg: true,
        detail21flg: true,
        detail22flg: true,
        detail23flg: true,
        detail24flg: true,
        detail2List: [],
        detail3List: [],
        detail4List: [],
        detail5List: [],
        detail6List: [],
        detail7List: [],
        detail8List: [],
        detail9List: [],
        detail10List: [],
        detail11List: [],
        detail12List: [],
        detail13List: [],
        detail14List: [],
        detail15List: [],
        detail16List: [],
        detail17List: [],
        detail18List: [],
        detail19List: [],
        detail20List: [],
        detail21List: [],
        detail22List: [],
        detail23List: [],
        detail24List: [],
      };
      return val;
    },
    /**
     * tableヘッダ項目更新
     */
    getTableHead(count) {
      this.details0 = String(count) + "時";
      this.details1 = String(count + 1) + "時";
      this.details2 = String(count + 2) + "時";
      this.details3 = String(count + 3) + "時";
      this.details4 = String(count + 4) + "時";
      this.details5 = String(count + 5) + "時";
      this.details6 = String(count + 6) + "時";
      this.details7 = String(count + 7) + "時";
      this.details8 = String(count + 8) + "時";
      this.details9 = String(count + 9) + "時";
      this.details10 = String(count + 10) + "時";
      this.details11 = String(count + 11) + "時";
      this.details12 = String(count + 12) + "時";
      this.details13 = String(count + 13) + "時";
      this.details14 = String(count + 14) + "時";
      this.details15 = String(count + 15) + "時";
      this.details16 = String(count + 16) + "時";
      this.details17 = String(count + 17) + "時";
      this.details18 = String(count + 18) + "時";
      this.details19 = String(count + 19) + "時";
      this.details20 = String(count + 20) + "時";
      this.details21 = String(count + 21) + "時";
      this.details22 = String(count + 22) + "時";
      this.details23 = String(count + 23) + "時";
    },

    getCountTime(count, addList) {
      addList.detail1time = ("00000" + String(count) + ":00").slice(-5);
      addList.detail2time = ("00000" + String(count + 1) + ":00").slice(-5);
      addList.detail3time = ("00000" + String(count + 2) + ":00").slice(-5);
      addList.detail4time = ("00000" + String(count + 3) + ":00").slice(-5);
      addList.detail5time = ("00000" + String(count + 4) + ":00").slice(-5);
      addList.detail6time = ("00000" + String(count + 5) + ":00").slice(-5);
      addList.detail7time = ("00000" + String(count + 6) + ":00").slice(-5);
      addList.detail8time = ("00000" + String(count + 7) + ":00").slice(-5);
      addList.detail9time = ("00000" + String(count + 8) + ":00").slice(-5);
      addList.detail10time = ("00000" + String(count + 9) + ":00").slice(-5);
      addList.detail11time = ("00000" + String(count + 10) + ":00").slice(-5);
      addList.detail12time = ("00000" + String(count + 11) + ":00").slice(-5);
      addList.detail13time = ("00000" + String(count + 12) + ":00").slice(-5);
      addList.detail14time = ("00000" + String(count + 13) + ":00").slice(-5);
      addList.detail15time = ("00000" + String(count + 14) + ":00").slice(-5);
      addList.detail16time = ("00000" + String(count + 15) + ":00").slice(-5);
      addList.detail17time = ("00000" + String(count + 16) + ":00").slice(-5);
      addList.detail18time = ("00000" + String(count + 17) + ":00").slice(-5);
      addList.detail19time = ("00000" + String(count + 18) + ":00").slice(-5);
      addList.detail20time = ("00000" + String(count + 19) + ":00").slice(-5);
      addList.detail21time = ("00000" + String(count + 20) + ":00").slice(-5);
      addList.detail22time = ("00000" + String(count + 21) + ":00").slice(-5);
      addList.detail23time = ("00000" + String(count + 22) + ":00").slice(-5);
      addList.detail24time = ("00000" + String(count + 23) + ":00").slice(-5);
      return addList;
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 戻るボタン押下
     */
    getBack() {
      this.backFlg = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
      this.updateDialogMessage = true;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      this.infoDialog.isOpen = false;
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);

      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 基準日を－１日します。
     */
    prevDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "");
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "add");
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      return dateUtil.formatDate(date);
    },

    /**
     * 日付入力(納入日)
     */
    changeDateDelivery(val) {
      this.deliveryDateCal = commonFunction.directInput(val);
    },
    /**
     * ユーザー取引先リストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.userSupplierList.length; i++) {
        if (this.userSupplierList[i].value == this.userSuppliersSelected) {
          return this.userSupplierList[i].name;
        }
      }
    },
    /**
     * 取引先リストボックス取得処理
     */
    setSrhClient() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.clientSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    /**
     * ユーザー品番リストボックス取得処理
     */
    setSrhUserProduct() {
      for (var i = 0; i < this.userProductCdList.length; i++) {
        if (this.userProductCdList[i].value == this.userProductCd) {
          return this.userProductCdList[i].name;
        }
      }
    },
    /**
     * 納入先工場コードリストボックス取得処理
     */
    setDestinationyPlant() {
      for (var i = 0; i < this.userDeliveryDestinationList.length; i++) {
        if (this.userDeliveryDestinationList[i].value == this.userDeliveryDestination) {
          return this.userDeliveryDestinationList[i].name;
        }
      }
    },
    /**
     * 数量入力時
     */
    changecount1(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail1 = value == 0 ? "" : value;
      this.inputList[index].detail1css =
        this.inputList[index].detail1 != this.inputList[index].detail1old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount2(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail2 = value == 0 ? "" : value;
      this.inputList[index].detail2css =
        this.inputList[index].detail2 != this.inputList[index].detail2old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount3(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail3 = value == 0 ? "" : value;
      this.inputList[index].detail3css =
        this.inputList[index].detail3 != this.inputList[index].detail3old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount4(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail4 = value == 0 ? "" : value;
      this.inputList[index].detail4css =
        this.inputList[index].detail4 != this.inputList[index].detail4old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount5(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail5 = value == 0 ? "" : value;
      this.inputList[index].detail5css =
        this.inputList[index].detail5 != this.inputList[index].detail5old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount6(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail6 = value == 0 ? "" : value;
      this.inputList[index].detail6css =
        this.inputList[index].detail6 != this.inputList[index].detail6old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount7(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail7 = value == 0 ? "" : value;
      this.inputList[index].detail7css =
        this.inputList[index].detail7 != this.inputList[index].detail7old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount8(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail8 = value == 0 ? "" : value;
      this.inputList[index].detail8css =
        this.inputList[index].detail8 != this.inputList[index].detail8old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount9(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail9 = value == 0 ? "" : value;
      this.inputList[index].detail9css =
        this.inputList[index].detail9 != this.inputList[index].detail9old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount10(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail10 = value == 0 ? "" : value;
      this.inputList[index].detail10css =
        this.inputList[index].detail10 != this.inputList[index].detail10old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount11(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail11 = value == 0 ? "" : value;
      this.inputList[index].detail11css =
        this.inputList[index].detail11 != this.inputList[index].detail11old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount12(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail12 = value == 0 ? "" : value;
      this.inputList[index].detail12css =
        this.inputList[index].detail12 != this.inputList[index].detail12old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount13(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail13 = value == 0 ? "" : value;
      this.inputList[index].detail13css =
        this.inputList[index].detail13 != this.inputList[index].detail13old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount14(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail14 = value == 0 ? "" : value;
      this.inputList[index].detail14css =
        this.inputList[index].detail14 != this.inputList[index].detail14old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount15(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail15 = value == 0 ? "" : value;
      this.inputList[index].detail15css =
        this.inputList[index].detail15 != this.inputList[index].detail15old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount16(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail16 = value == 0 ? "" : value;
      this.inputList[index].detail16css =
        this.inputList[index].detail16 != this.inputList[index].detail16old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount17(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail17 = value == 0 ? "" : value;
      this.inputList[index].detail17css =
        this.inputList[index].detail17 != this.inputList[index].detail17old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount18(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail18 = value == 0 ? "" : value;
      this.inputList[index].detail18css =
        this.inputList[index].detail18 != this.inputList[index].detail18old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount19(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail19 = value == 0 ? "" : value;
      this.inputList[index].detail19css =
        this.inputList[index].detail19 != this.inputList[index].detail19old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount20(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail20 = value == 0 ? "" : value;
      this.inputList[index].detail20css =
        this.inputList[index].detail20 != this.inputList[index].detail20old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount21(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail21 = value == 0 ? "" : value;
      this.inputList[index].detail21css =
        this.inputList[index].detail21 != this.inputList[index].detail21old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount22(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail22 = value == 0 ? "" : value;
      this.inputList[index].detail22css =
        this.inputList[index].detail22 != this.inputList[index].detail22old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount23(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail23 = value == 0 ? "" : value;
      this.inputList[index].detail23css =
        this.inputList[index].detail23 != this.inputList[index].detail23old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    changecount24(val, index, data) {
      var value = commonFunction.getDelimiter(data, 7);
      this.inputList[index].detail24 = value == 0 ? "" : value;
      this.inputList[index].detail24css =
        this.inputList[index].detail24 != this.inputList[index].detail24old
          ? "background-color: #FFFF00"
          : "";
      this.getchangeCount(index);
    },
    /**
     * 出荷数量算出
     */
    getchangeCount(index) {
      var totalshipping = 0;
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail1);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail2);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail3);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail4);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail5);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail6);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail7);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail8);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail9);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail10);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail11);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail12);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail13);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail14);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail15);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail16);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail17);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail18);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail19);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail20);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail21);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail22);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail23);
      totalshipping = this.getCountCheck(totalshipping, this.inputList[index].detail24);
      this.inputList[index].totalQuantity = commonUtil.formatToCurrency(Number(totalshipping));
    },

    getCountCheck(totalshipping, detail) {
      if (detail != "") {
        detail = detail.replace(/[^0-9]/gi, "");
        totalshipping += Number(detail);
      }
      return totalshipping;
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    changecount(val) {
      var value = commonFunction.getDelimiter(val, 7);
      this.containerCapacityQuantity = value == 0 ? "1" : value;
    },
    /**
     * 表示項目(明細クリック時)
     */
    detailclick(item, index, val, time) {
      this.dialogDetails = true;
      this.detailDialogData.deliveryDate = this.inputList[index].installationDate;
      this.detailDialogData.deliveryTime = time;
      this.detailDialogData.shipmentQuantity = item;
      this.detailDialogData.index = index;
      this.detailDialogData.value = val;
      if (val == "1") {
        this.detailDialogData.detailList = this.inputList[index].detail1List;
      } else if (val == "2") {
        this.detailDialogData.detailList = this.inputList[index].detail2List;
      } else if (val == "3") {
        this.detailDialogData.detailList = this.inputList[index].detail3List;
      } else if (val == "4") {
        this.detailDialogData.detailList = this.inputList[index].detail4List;
      } else if (val == "5") {
        this.detailDialogData.detailList = this.inputList[index].detail5List;
      } else if (val == "6") {
        this.detailDialogData.detailList = this.inputList[index].detail6List;
      } else if (val == "7") {
        this.detailDialogData.detailList = this.inputList[index].detail7List;
      } else if (val == "8") {
        this.detailDialogData.detailList = this.inputList[index].detail8List;
      } else if (val == "9") {
        this.detailDialogData.detailList = this.inputList[index].detail9List;
      } else if (val == "10") {
        this.detailDialogData.detailList = this.inputList[index].detail10List;
      } else if (val == "11") {
        this.detailDialogData.detailList = this.inputList[index].detail11List;
      } else if (val == "12") {
        this.detailDialogData.detailList = this.inputList[index].detail12List;
      } else if (val == "13") {
        this.detailDialogData.detailList = this.inputList[index].detail13List;
      } else if (val == "14") {
        this.detailDialogData.detailList = this.inputList[index].detail14List;
      } else if (val == "15") {
        this.detailDialogData.detailList = this.inputList[index].detail15List;
      } else if (val == "16") {
        this.detailDialogData.detailList = this.inputList[index].detail16List;
      } else if (val == "17") {
        this.detailDialogData.detailList = this.inputList[index].detail17List;
      } else if (val == "18") {
        this.detailDialogData.detailList = this.inputList[index].detail18List;
      } else if (val == "19") {
        this.detailDialogData.detailList = this.inputList[index].detail19List;
      } else if (val == "20") {
        this.detailDialogData.detailList = this.inputList[index].detail20List;
      } else if (val == "21") {
        this.detailDialogData.detailList = this.inputList[index].detail21List;
      } else if (val == "22") {
        this.detailDialogData.detailList = this.inputList[index].detail22List;
      } else if (val == "23") {
        this.detailDialogData.detailList = this.inputList[index].detail23List;
      } else if (val == "24") {
        this.detailDialogData.detailList = this.inputList[index].detail24List;
      }
    },
    /**
     * 出荷数登録閉じるボタン押下時
     */
    closePopup() {
      this.dialogDetails = false;
    },
    /**
     * 出荷数登録登録ボタン押下時
     */
    insertPopup() {
      if (this.detailDialogData.value == "1") {
        this.changecount1("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail1List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "2") {
        this.changecount2("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail2List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "3") {
        this.changecount3("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail3List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "4") {
        this.changecount4("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail4List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "5") {
        this.changecount5("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail5List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "6") {
        this.changecount6("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail6List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "7") {
        this.changecount7("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail7List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "8") {
        this.changecount8("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail8List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "9") {
        this.changecount9("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail9List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "10") {
        this.changecount10("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail10List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "11") {
        this.changecount11("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail11List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "12") {
        this.changecount12("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail12List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "13") {
        this.changecount13("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail13List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "14") {
        this.changecount14("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail14List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "15") {
        this.changecount15("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail15List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "16") {
        this.changecount16("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail16List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "17") {
        this.changecount17("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail17List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "18") {
        this.changecount18("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail18List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "19") {
        this.changecount19("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail19List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "20") {
        this.changecount20("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail20List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "21") {
        this.changecount21("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail21List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "22") {
        this.changecount22("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail22List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "23") {
        this.changecount23("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail23List = this.detailDialogData.detailList;
      }
      if (this.detailDialogData.value == "24") {
        this.changecount24("", this.detailDialogData.index, this.detailDialogData.shipmentQuantity);
        this.inputList[this.detailDialogData.index].detail24List = this.detailDialogData.detailList;
      }
      this.dialogDetails = false;
    },
    selectPopup() {
      this.headerDeliveryPlan = this.slectionDialogData.headerDeliveryPlan;
      this.destinationCd = this.slectionDialogData.destinationCd;
      this.getAcquisition();
    },
    sortingPopup() {
      this.headerDeliveryPlan = this.sortingInfoSearchDialogData.headerDeliveryPlan;
      this.headerDeliveryDestinationy = this.sortingInfoSearchDialogData.headerDeliveryDestinationy;
      this.userPlant = this.sortingInfoSearchDialogData.userPlant;
      this.userSupplierList = this.sortingInfoSearchDialogData.userSupplierList;
      this.userSuppliersSelected = this.sortingInfoSearchDialogData.userSuppliersSelected;
      if (this.sortingInfoSearchDialogData.userDeliveryDestination != "") {
        this.check1 = false;
      }
      this.userDeliveryDestination = this.sortingInfoSearchDialogData.userDeliveryDestination;
      this.userDeliveryDestinationList =
        this.sortingInfoSearchDialogData.userDeliveryDestinationList;
      if (this.sortingInfoSearchDialogData.clientSelected != "") {
        this.check = false;
      }
      this.clientSelected = this.sortingInfoSearchDialogData.clientSelected;
      this.supplierList = this.sortingInfoSearchDialogData.supplierList;
      this.makerFactory = this.sortingInfoSearchDialogData.userPlant;
      this.sortingCheck = true;
      this.cargosStyle = "display: none";
      this.sortingCheckRow = "margin-top: 25px";
      this.inputList = [];
    },
    getApiList() {
      this.sortingInfodialogSearch = true;
      this.sortingInfoSearchDialogData.userSupplierList = this.userSupplierList;
      this.sortingInfoSearchDialogData.userSuppliersSelected = this.userSuppliersSelected;
      // this.sortingInfoSearchDialogData.userDeliveryDestinationList =
      //   this.userDeliveryDestinationList;
      this.sortingInfoSearchDialogData.deliveryDate = this.deliveryDate;
      this.sortingInfoSearchDialogData.deliveryDateCal = this.deliveryDateCal;
      this.sortingInfoSearchDialogData.headerDeliveryPlan = this.headerDeliveryPlan;
      this.sortingInfoSearchDialogData.headerDeliveryDestinationy = this.headerDeliveryDestinationy;
      this.sortingInfoSearchDialogData.makerFactory = this.makerFactory;
      if (this.clientSelected != "" && this.clientSelected != null) {
        this.sortingInfoSearchDialogData.clientSelected = this.clientSelected;
        this.sortingInfoSearchDialogData.supplierList = this.supplierList;
        this.sortingInfoSearchDialogData.userMakerSected = "02";
      } else {
        this.sortingInfoSearchDialogData.clientSelected = "";
        this.sortingInfoSearchDialogData.supplierList = [];
        this.sortingInfoSearchDialogData.userMakerSected = "01";
      }
    },
  },
  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    searchClient(val) {
      if (!this.check) {
        if (val == null) {
          this.check = true;
        }
        return;
      }
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.selectHintClient) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得

          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.CLIENT,
            dateTimeHelper.convertUTC(this.deliveryDate),
            undefined,
            undefined,
            val,
            sessionStorage.getItem("sales_office_sid")
          );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.supplierList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.supplierList.length = 0;
          this.supplierList = [];
        }
      }
    },
    // ユーザー品番取得処理
    searchUserProductCd(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.userSuppliersSelected == null || this.userSuppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // 選択した会社コードを取得

          const fromList = getParameter.getUserItemsBizAddName(this.userSuppliersSelected, val);
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.userProductCdList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.userProductCdList.length = 0;
          this.userProductCdList = [];
        }
      }
    },
    dialog(val) {
      val || this.close();
    },
    deliveryDateCal(val) {
      this.deliveryDate = this.formatDate(val);
    },
    dataIssueDateFromCal(val) {
      this.dataIssueDateFrom = this.formatDate(val);
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.autoUpdate ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 110px;
}

.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 110px;
}
.textbox {
  width: 11.2rem;
}
a {
  text-decoration: underline;
}

#listData.v-data-table ::v-deep th {
  padding: 0 0px;
}
#listData.v-data-table ::v-deep th .headerclass1,
#listData.v-data-table ::v-deep td #table-detail1 {
  background-color: #e0ffff;
}
#listData.v-data-table ::v-deep th .headerclass2 {
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
}
#listData.v-data-table ::v-deep td #table-detail2 {
  background-color: #ffffff;
}
#listData.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 534px;
  height: 100%;
}
#listData.v-data-table ::v-deep td.text-left {
  padding: 0 0px;
}
#listData.v-data-table ::v-deep td .v-text-field.input-number {
  margin: 0px 0 0 0;
  height: 2.5rem;
}
#listData.v-data-table ::v-deep td .v-text-field--outlined {
  border-radius: 0px;
}
#listData.v-data-table ::v-deep td {
  border-bottom: none;
}
#listData.v-data-table ::v-deep td .displaystyle1 {
  height: 2.5rem;
  border: 1px solid #8b9e9e !important;
  background-color: #e0ffff;
  text-align: right;
  padding-top: 5px;
  font-size: 16px;
  padding-right: 2.2rem;
}
#listData.v-data-table ::v-deep td .displaystyle2 {
  height: 2.5rem;
  border: 1px solid #8b9e9e !important;
  background-color: #ffffff;
  text-align: right;
  padding-top: 5px;
  font-size: 16px;
  padding-right: 2.2rem;
}
#listData.v-data-table ::v-deep table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
